import axios, { handleRequest } from './kiddy-connector'

// ========================================================= API =========================================================

const GetListGroupAPI = (params) => axios.get('/group/myGroup', { params }).then(response => response.list)
const GetGroupDetailAPI = (id) => axios.get(`/group/detailGroup/${id}`).then(response => response.group)
const PostCreateGroupApi = (data) => axios.post('/group/teacher/newCreateGroup', data)
const PatchPinGroupApi = (groupId) => axios.patch(`/group/pinGroup/${groupId}`,{})
const PatchUnPinGroupApi = (groupId) => axios.patch(`/group/unpinGroup/${groupId}`,{})
const GetListExamInGroupAPI = (groupId) => axios.get(`/group/exams/${groupId}`).then(response => response.list)
const GetListMemberInGroupAPI = (groupId) => axios.get(`/group/members/${groupId}`).then(response => response.list)
const PostListRequestAPI = (groupId, param) => axios.post(`/group/teacher/listRequest/${groupId}`,param)
const PatchPinExamGroupApi = (groupId, examId) => axios.patch(`/group/teacher/pinExamInGroup/${groupId}/${examId}`,{})
const PatchUnPinExamGroupApi = (groupId, examId) => axios.patch(`/group/teacher/unpinExamInGroup/${groupId}/${examId}`,{})
const PatchActiveExamGroupApi = (groupId, examId) => axios.patch(`/group/teacher/activeExam/${groupId}/${examId}`,{})
const PatchInActiveExamGroupApi = (groupId, examId) => axios.patch(`/group/teacher/inactiveExam/${groupId}/${examId}`,{})
const DeleteGroupAPI = (groupId) => axios.delete(`/group/teacher/deleteGroup/${groupId}`)
const LeaveGroupAPI = (groupId) => axios.patch(`/group/leaveGroup/${groupId}`,{})
const PatchEditApi = (groupId, param) => axios.patch(`/group/teacher/editGroup/${groupId}`,param)
const GetNewQrCodeAPI = (groupId) => axios.patch(`/group/teacher/newReQRcode/${groupId}`,{})
const DeleteExamInGroupAPI = (groupId,examId) => axios.patch(`/group/teacher/deleteExamInGroup/${groupId}/${examId}`,{})
const GetListGroupHasNotExamAPI = (examId) => axios.get(`group/teacher/myGroupToAdd/${examId}`).then(response => response.list.filter(group => group.haveThisExam === false))
const AddExamToGroupsAPI = (examId, groups) => axios.patch(`group/teacher/addExamToListMyGroup/${examId}`, { groups })
const GetListMyExamToGroupAddToGroupAPI = (groupId) => axios.get(`group/teacher/myExamToAdd/${groupId}`).then(response => response.list)
const AddListMyExamToGroupAPI = (groupId,exam) => axios.patch(`group/teacher/addListMyExamToGroup/${groupId}`,exam)
const DeleteMemberGroupAPI = (groupId,memberId) => axios.patch(`group/teacher/deleteMember/${groupId}/${memberId}`,{})
const DeleteBatchMemberGroupAPI = (groupId,members) => axios.patch(`group/teacher/deleteBatchMember/${groupId}`,members) 
const AllowRequestAPI = (groupId,allows) => axios.patch(`group/teacher/allowRequest/${groupId}`,allows)
const RejectRequestAPI = (groupId,allows) => axios.patch(`group/teacher/rejectRequest/${groupId}`,allows)
const GetTeacherOverallGroupAPI = (groupId, examId) => axios.get(`group/teacher/overAllGroup/${examId}/${groupId}`)
const GetReportRankingGroupAPI = (groupId, examId) => axios.get(`group/rankingGroup/${examId}/${groupId}`).then(response => response.list)
const GetReportMyRankingAPI = (groupId, examId, memberId) => axios.get(`group/myRanking?memberId=${memberId}&examId=${examId}&groupId=${groupId}`)
const GetReportTestingHistoryAPI = (groupId, examId, memberId) => axios.get(`group/testingHistory?memberId=${memberId}&examId=${examId}&groupId=${groupId}`).then(response => response.list)
const GetResultAPI = (examId) => axios.get(`group/resultTesting/${examId}`)
const GetAnswertAPI = (examId) => axios.get(`group/answerTesting/${examId}`)
const GetStdResultAPI = (examId, groupId) => axios.get(`group/student/latestResult/${examId}/${groupId}`)
const GetStdLastAnswerAPI = (examId, groupId) => axios.get(`group/student/latestAnswer/${examId}/${groupId}`)
const GetStdAnswerAPI = (testingId) => axios.get(`group/answerTesting/${testingId}`)
const SearchGroupAPI = (params) => axios.post('/group/searchGroup',params)
const SearchLinkWebAPI = (params) => axios.post('/group/shareLinkWeb',params)
const RequestAPI = (groupId) => axios.patch(`group/requestJoin/${groupId}`,{})
const CancelJoinAPI = (groupId) => axios.patch(`group/cancelJoin/${groupId}`,{})
const GetListMyGroupToReportAPI = (examId) => axios.get(`group/teacher/myGroupToReport/${examId}`).then(response => response.list)
const GetListRankingMemberToReportAPI = (examId) => axios.get(`group/teacher/rankingMembersToReport/${examId}`).then(response => response.list)
const GetExamInGroupToReportAPI = (groupId) => axios.get(`group/teacher/examsInGroupToReport/${groupId}`).then(response => response.list)
const GetStudentInGroupToReportAPI = (groupId) => axios.get(`group/teacher/studentInGroupToReport/${groupId}`).then(response => response.list)
const GetAllTestingHistoryAPI = (groupId, memberId, params) => axios.get(`group/teacher/allTestingHistory/${groupId}/${memberId}/LIST`, { params }).then(response => response)
const GetStudentAnalysisAPI = (groupId, memberId, params) => axios.get(`group/teacher/studentAnalysis/${groupId}/${memberId}`, { params }).then(response => response)
const GetGroupToReportMyExamAPI = (examId) => axios.get(`group/teacher/myGroupToReport/${examId}`).then(response => response)
const GetRankingMembersToReportMyExamAPI = (examId) => axios.get(`group/teacher/rankingMembersToReport/${examId}`).then(response => response)
const GetDataReportOverAllGroupAPI = (groupId, exams) => axios.post(`group/teacher/pdfReport/reportOverAllGroup/${groupId}`, { exams }).then(response => response)
const GetOverAllMemberPDFAPI = (groupId, members) => axios.post(`group/teacher/pdfReport/reportOverAllMember/${groupId}`, { members }).then(response => response)
const ChangeDeviceStartGroupTestingAPI = ({testingId, examId, groupId}) => axios.get(`group/changeDeviceStartTesting`, {params : {testingId, examId, groupId} } ).then(response => response)
// ========================================================= Service =========================================================

export const getListGroupService = (params) => handleRequest(GetListGroupAPI(params)) 
export const getGroupDetailService = (params) => handleRequest(GetGroupDetailAPI(params)) 
export const createGroupService = (data) => handleRequest(PostCreateGroupApi(data)) 
export const pinGroupService = (groupId) => handleRequest(PatchPinGroupApi(groupId)) 
export const unPinGroupService = (groupId) => handleRequest(PatchUnPinGroupApi(groupId)) 
export const getListExamInGroupService = (params) => handleRequest(GetListExamInGroupAPI(params))
export const getListMemberGroupService = (params) => handleRequest(GetListMemberInGroupAPI(params))
export const pinExamGroupService = (groupId, examId) => handleRequest(PatchPinExamGroupApi(groupId, examId))
export const getRequestGroupService = (groupId, param) => handleRequest(PostListRequestAPI(groupId, param))
export const unPinExamGroupService = (groupId, examId) => handleRequest(PatchUnPinExamGroupApi(groupId, examId))
export const ActiveExamGroupService = (groupId, examId) => handleRequest(PatchActiveExamGroupApi(groupId, examId))
export const InActiveExamGroupService = (groupId, examId) => handleRequest(PatchInActiveExamGroupApi(groupId, examId))
export const deleteGroupService = (groupId) => handleRequest(DeleteGroupAPI(groupId))
export const leaveGroupService = (groupId) => handleRequest(LeaveGroupAPI(groupId))
export const patchEditGroupService = (groupId, param) => handleRequest(PatchEditApi(groupId, param))
export const GetNewQrCodeService = (groupId) => handleRequest(GetNewQrCodeAPI(groupId))
export const DeleteExamInGroupService = (groupId,examId) => handleRequest(DeleteExamInGroupAPI(groupId,examId))
export const getNewQrCodeService = (groupId) => handleRequest(GetNewQrCodeAPI(groupId))
export const getListGroupHasNotExamService = (examId) => handleRequest(GetListGroupHasNotExamAPI(examId))
export const addExamToGroupsService = (examId, groupId) => handleRequest(AddExamToGroupsAPI(examId, groupId))
export const GetListMyExamToGroupAddToGroupService = (groupId) => handleRequest(GetListMyExamToGroupAddToGroupAPI(groupId))
export const AddListMyExamToGroupService = (groupId,exam) => handleRequest(AddListMyExamToGroupAPI(groupId,exam))
export const DeleteMemberGroupService = (groupId,memberId) => handleRequest(DeleteMemberGroupAPI(groupId,memberId))
export const DeleteBatchMemberGroupService = (groupId,members) => handleRequest(DeleteBatchMemberGroupAPI(groupId,members))
export const AllowRequestService = (groupId, allows) => handleRequest(AllowRequestAPI(groupId,allows))
export const RejectRequestService = (groupId, allows) => handleRequest(RejectRequestAPI(groupId,allows))
export const GetTeacherOverAllGroupService = (groupId,examId) => handleRequest(GetTeacherOverallGroupAPI(groupId,examId))
export const GetReportRankingGroupService = (groupId,examId) => handleRequest(GetReportRankingGroupAPI(groupId,examId))
export const GetReportMyRankingService = (groupId,examId,memberId='') => handleRequest(GetReportMyRankingAPI(groupId,examId,memberId))
export const GetReportTestingHistoryService = (groupId,examId,memberId='') => handleRequest(GetReportTestingHistoryAPI(groupId,examId,memberId))
export const GetResultService = (examId) => handleRequest(GetResultAPI(examId))
export const GetAnswerService = (examId) => handleRequest(GetAnswertAPI(examId))
export const GetStdResultService = (examId, groupId) => handleRequest(GetStdResultAPI(examId, groupId))
export const GetStdAnswerService = (testingId) => handleRequest(GetStdAnswerAPI(testingId))
export const GetStdLastAnswerService = (examId, groupId) => handleRequest(GetStdLastAnswerAPI(examId, groupId))
export const SearchGroupService = (params) => handleRequest(SearchGroupAPI(params))
export const SearchLinkWebAPIService = (params) => handleRequest(SearchLinkWebAPI(params))
export const requestService = (groupId) => handleRequest(RequestAPI(groupId))
export const cancelJoinService = (groupId) => handleRequest(CancelJoinAPI(groupId))
export const GetListMyGroupToReportService = (examId) => handleRequest(GetListMyGroupToReportAPI(examId))
export const GetListRankingMemberToReportService = (examId) => handleRequest(GetListRankingMemberToReportAPI(examId))
export const GetExamInGroupToReportSerivce = (groupId) => handleRequest(GetExamInGroupToReportAPI(groupId))
export const GetStudentInGroupToReportService = (groupId) => handleRequest(GetStudentInGroupToReportAPI(groupId))
export const GetAllTestingHistoryService = (groupId, memberId, params) => handleRequest(GetAllTestingHistoryAPI(groupId, memberId, params))
export const GetStudentAnalysisService = (groupId, memberId, params) => handleRequest(GetStudentAnalysisAPI(groupId, memberId, params))
export const GetGroupToReportMyExamService = (examId) => handleRequest(GetGroupToReportMyExamAPI(examId))
export const GetRankingMembersToReportMyExamService = (examId) => handleRequest(GetRankingMembersToReportMyExamAPI(examId))
export const gethDataReportOverAllGroupService = (groupId, exams) => handleRequest(GetDataReportOverAllGroupAPI(groupId, exams))
export const getOverAllMemberPDFService = (groupId, exams) => handleRequest(GetOverAllMemberPDFAPI(groupId, exams))
export const ChangeDeviceStartGroupTestingService = ({testingId, examId, groupId}) => handleRequest(ChangeDeviceStartGroupTestingAPI({testingId, examId, groupId}))