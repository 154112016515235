import _ from 'lodash'
import { DEFAULT_TARGET_LANGUAGE } from './localstorage'



// vocabLogin = loginVocab
export const mapLanguageEngThai = {}

const flipObject = (obj) => Object.fromEntries(Object.entries(obj).map(([key, value]) => [value, key]))

export function TranslateLanguage() {
  this.mapingData = {} || undefined
}

TranslateLanguage.prototype = {
  addMaping: function (addSource) {
    if (_.isEmpty(addSource) === false) {
      return this.mapingData = { ...this.mapingData, ...addSource }
    }
    return this.mapingData
  },
  convert: function (sentence = '', op = {
    sourceLang: 'th',
    targetLang: DEFAULT_TARGET_LANGUAGE,
    withReplace: false
  }) {
    const defaultStatement = sentence || ''
    const options = { ...op, sourceLang: 'th', targetLang: DEFAULT_TARGET_LANGUAGE }
    const { sourceLang, targetLang } = options
    if (!this?.mapingData) {
      return
    }
    if (options.withReplace === true) {
      return replaceWithBlank(sentence, this.mapingData) || defaultStatement
    }
    if (sourceLang === 'en' && targetLang === 'th') {
      return _.cloneDeep(this.mapingData[sentence]) || defaultStatement
    }
    if (sourceLang === 'th' && targetLang === 'en') {
      return _.cloneDeep(flipObject(this.mapingData)[sentence]) || defaultStatement
    }
    return defaultStatement
  }
}

const replaceWithBlank = (sentence, mapData) => (sentence.split(/(\s|:|,|=|!|&)/g).map((value) => {
  if (/(\s|:|,|=|!|&)/g.exec(value)) {
    return /(\s|:|,|=|!|&)/g.exec(value)[0]
  }
  if (_.cloneDeep(flipObject(mapData)[value])) {
    return _.cloneDeep(flipObject(mapData)[value])
  }
  return value
}).filter(v => v).join(''))


// if (require('pages/Login/Login')) {
//   // const { vocabLogin } = require('pages/Login/Login')
//   console.log(require('pages/Login/Login')?.vocabLogin)
// }