import React, { useState } from "react";
import styles from "../Header.module.scss";

import { GRADES } from "util/config";
import { Dropdown, Avatar, Tag } from "antd";
import IconProfile from "assets/icons/icon-profile-cu.png";
import iconLogOut from "assets/icons/icon-logout-cu.png";
import IconDropdownBlack from "assets/icons/icon-dropdown-black.svg";
import MenuDropdown from "components/Menu/MenuDropdown";
import KidProfile from "assets/icons/profile/Default.svg";
import ListDropdown from "components/List/ListDropdown";
import { TranslateLanguage } from "util/Translate";
import { vocabUserRightNav } from "util/Translate.variable";
import useWindowSize from "hook/useWindowSize";
import { Modal, Button } from "antd";
import iconLogoout from "assets/icons/profile/icon-log-out-cu.svg";

const translate = new TranslateLanguage();
translate.addMaping(vocabUserRightNav);

export default function UserRightNav(props) {
  const { user } = props;
  const { width } = useWindowSize();

  const [visibleLogout, setVisibleLogout] = React.useState(false);

  const handleClickSignout = () => {
    setVisibleLogout(true);
  };

  const handleCancelSignout = () => {
    setVisibleLogout(false);
  };

  const handleSignOut = () => {
    props.onSignOut();
  };

  return (
    <div className={styles.headerProfile}>
      <div className={styles.subProfile}>
        <span
          style={{ display: "flex", cursor: "pointer" }}
          onClick={() => {
            props.props.history.push("/profile");
          }}
        >
          {width <= 500 ? (
            <></>
          ) : (
            <div className={styles.display}>
              <Avatar
                className={styles.avatar}
                src={user?.imageProfile || KidProfile}
              />
            </div>
          )}
          <div className={styles.nameAndSchool}>
            <div className={styles.divDisplayName}>
              <span className={styles.displayName}>
                {user?.displayName || "Guest"}
              </span>
            </div>
          </div>
        </span>
        <Dropdown
          overlay={MenuDropdown([
            {
              label: (
                <ListDropdown
                  text={translate.convert("ข้อมูลผู้ใช้งาน")}
                  icon={IconProfile}
                  action={props.onProfile}
                />
              ),
            },
            {
              label: (
                <ListDropdown
                  text={translate.convert("ออกจากระบบ")}
                  icon={iconLogOut}
                  action={handleClickSignout}
                />
              ),
            },
          ])}
          trigger={["click"]}
          placement="bottomRight"
          arrow
        >
          <div style={{ cursor: "pointer" }}>
            <Avatar alt="dropdownprofile" src={IconDropdownBlack} />
          </div>
        </Dropdown>
      </div>
      <Modal
        open={visibleLogout}
        onCancel={handleCancelSignout}
        footer={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button type="primary" onClick={handleSignOut}>
              ยืนยัน
            </Button>
            <Button onClick={handleCancelSignout}>ยกเลิก</Button>
          </div>
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#fde8f1",
              width: "150px",
              height: "150px",
              borderRadius: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={iconLogoout}
              alt="iconLogoout"
              style={{ width: "75px" }}
            />
          </div>
          <div
            style={{
              marginTop: "2rem",
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            ออกจากระบบ
          </div>
          <div style={{ marginBottom: ".75rem" }}>
            คุณต้องการออกจะระบบหรือไม่?
          </div>
        </div>
      </Modal>
    </div>
  );
}
