import React, { useState, useEffect } from "react";
//Library
import { Layout, Button, BackTop } from "antd";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import { message } from "antd";
import styles from "./LayoutCMS.module.scss";
// import { isSupported } from 'firebase/messaging'

//Icon SVG
import IconExam from "assets/icons/menu/icon-exam-selected-pink.png";
import IconExamGray from "assets/icons/menu/icon-exam-non-selected-cu.svg";

import IconMyExam from "assets/icons/menu/icon-my-exam-selected.svg";
import IconMyExamGray from "assets/icons/menu/icon-my-exam-non-selected.svg";

import IconGroup from "assets/icons/menu/icon-group-selected.svg";
import IconGroupGray from "assets/icons/menu/icon-group-non-selected.svg";

import IconBookMark from "assets/icons/menu/icon-bookmark-selected-cu.svg";
import IconBookMarkGray from "assets/icons/menu/icon-bookmark-non-selected-cu.svg";

import IconShopSelected from "assets/icons/menu/icon-shop-selected-cu.svg";
import IconShopNonSelected from "assets/icons/menu/icon-shop-non-selected-cu.svg";

import IconPretest from "assets/icons/menu/icon-pretest-selected-cu.svg";
import IconPretestGray from "assets/icons/menu/icon-pretest-non-selected-cu.svg";

//Components
import Footer from "components/Footer/Footer";
import useAuth from "context/auth";
import { logoutService } from "api/auth";
import { USER_ROLE } from "util/config";
import { HeaderCMS, HeaderGuest } from "components/Header";
import { actionType } from "reducers/auth";
import dayjs from "dayjs";
import { getCookie, setCookie } from "util/helper";
import "util/firebase-connector";
import { TranslateLanguage } from "util/Translate";
import { vacabLayoutCMS } from "util/Translate.variable";
import { UnorderedListOutlined } from "@ant-design/icons";
import useWindowSize from "hook/useWindowSize";
import _ from "lodash";

const translate = new TranslateLanguage();
translate.addMaping(vacabLayoutCMS);
const { Content, Sider } = Layout;

const setIconMenu = (selectedIcon, icon) => (isSelect) =>
  isSelect ? selectedIcon : icon;

export const userMenuSchema = {
  guest: [
    // {
    //   text: translate.convert("ข้อสอบ"),
    //   getIcon: setIconMenu(IconExam, IconExamGray),
    //   path: "/home",
    //   activeKey: "home",
    // },
    {
      text: translate.convert("ชุดข้อสอบ"),
      getIcon: setIconMenu(IconExam, IconExamGray),
      path: "/bundle",
      activeKey: "bundle",
    },
    // {
    //   text: translate.convert("บุ๊กมาร์ก"),
    //   getIcon: setIconMenu(IconBookMark, IconBookMarkGray),
    //   path: "/bookmark",
    //   activeKey: "bookmark",
    // },
  ],
  normalStudent: [
    // {
    //   text: translate.convert("ข้อสอบ"),
    //   getIcon: setIconMenu(IconExam, IconExamGray),
    //   path: "/home",
    //   activeKey: "home",
    // },
    {
      text: translate.convert("ชุดข้อสอบ"),
      getIcon: setIconMenu(IconExam, IconExamGray),
      path: "/bundle",
      activeKey: "bundle",
    },
    {
      text: translate.convert("Pretest"),
      getIcon: setIconMenu(IconPretest, IconPretestGray),
      path: "/pretest",
      activeKey: "pretest",
    },
    // {
    //   text: translate.convert("บุ๊กมาร์ก"),
    //   getIcon: setIconMenu(IconBookMark, IconBookMarkGray),
    //   path: "/bookmark",
    //   activeKey: "bookmark",
    // },
    // {
    //   text: translate.convert("ซื้อแพ็กเกจ"),
    //   getIcon: setIconMenu(IconShopSelected, IconShopNonSelected),
    //   path: "/package",
    //   activeKey: "package",
    // },
  ],
  schoolCollabStudent: [
    // {
    //   text: translate.convert("ข้อสอบ"),
    //   getIcon: setIconMenu(IconExam, IconExamGray),
    //   path: "/home",
    //   activeKey: "home",
    // },
    {
      text: translate.convert("ชุดข้อสอบ"),
      getIcon: setIconMenu(IconExam, IconExamGray),
      path: "/bundle",
      activeKey: "bundle",
    },
    // {
    //   text: translate.convert('กลุ่ม'),
    //   getIcon: setIconMenu(IconGroup, IconGroupGray),
    //   path: '/group',
    //   activeKey: 'group'
    // },
    {
      text: translate.convert("Pretest"),
      getIcon: setIconMenu(IconPretest, IconPretestGray),
      path: "/pretest",
      activeKey: "pretest",
    },
    // {
    //   text: translate.convert("บุ๊กมาร์ก"),
    //   getIcon: setIconMenu(IconBookMark, IconBookMarkGray),
    //   path: "/bookmark",
    //   activeKey: "bookmark",
    // },
  ],
  schoolCollabTeacher: [
    // {
    //   text: translate.convert("ข้อสอบ"),
    //   getIcon: setIconMenu(IconExam, IconExamGray),
    //   path: "/home",
    //   activeKey: "home",
    // },
    {
      text: translate.convert("ข้อสอบ"),
      getIcon: setIconMenu(IconExam, IconExamGray),
      path: "/bundle",
      activeKey: "bundle",
    },
    // {
    //   text: translate.convert("ข้อสอบของฉัน"),
    //   getIcon: setIconMenu(IconMyExam, IconMyExamGray),
    //   path: "/my-exam",
    //   activeKey: "my-exam",
    // },
    // {
    //   text: translate.convert('กลุ่ม'),
    //   getIcon: setIconMenu(IconGroup, IconGroupGray),
    //   path: '/group',
    //   activeKey: 'group'
    // },
    // {
    //   text: translate.convert("บุ๊กมาร์ก"),
    //   getIcon: setIconMenu(IconBookMark, IconBookMarkGray),
    //   path: "/bookmark",
    //   activeKey: "bookmark",
    // },
  ],
};

const LayoutCMS = (props) => {
  const prop = props.children.props;
  const {
    state: { user, isAuthenticated },
  } = useAuth();
  const [roleMenu, setRoleMenu] = useState(userMenuSchema.guest);
  const [defaultHover, setDefaultHover] = useState(-1);
  const [acceptCookie, setAcceptCookie] = useState(false);
  // const [supportBrowser, setSupportBrowser] = useState(false)
  const { dispatch } = useAuth();
  const { width, height } = useWindowSize();

  useEffect(() => {
    if (isAuthenticated) {
      if (user.role === USER_ROLE.TEACHER) {
        setRoleMenu(userMenuSchema.schoolCollabTeacher);
      } else if (user.role === USER_ROLE.STUDENT && user.schoolCollabName) {
        setRoleMenu(userMenuSchema.schoolCollabStudent);
      } else if (user.role === USER_ROLE.STUDENT && !user.schoolCollabName) {
        setRoleMenu(userMenuSchema.normalStudent);
      }
    } else {
      setRoleMenu(userMenuSchema.guest);
    }
    // isSupported().then((suports) => {
    //   if (suports) {
    //     setSupportBrowser(true)
    //   }
    // })

    return () => props.children;
  }, []);

  const sendGAEvent = (path) => {
    ReactGA.event({
      category: "Menu",
      action: "Click Left Side Menu",
      label: path,
    });
  };
  const handleSignOut = async () => {
    message.error("แพ็กเกจของคุณหมดอายุแล้ว");
    const res = await logoutService();
    if (res.error) return message.error(`ไม่สามารถออกจากระบบได้ ${res.error}`);
    dispatch({ type: actionType.LOGOUT });
    props.children.props.history.push("/login");
    ReactGA.event({
      category: "User",
      action: "logout",
      label: "logout",
    });
  };

  if (user?.role === USER_ROLE.TEACHER) {
    handleSignOut();
  }

  const handleAcceptCookie = () => {
    setCookie("acceptCookie", "true");
    setAcceptCookie(true);
  };

  return (
    <div className={styles.content}>
      <BackTop />
      <Layout className={styles.divLayout}>
        {isAuthenticated ? (
          <HeaderCMS
            {...props.children.props}
            user={user}
            roleMenu={roleMenu}
          />
        ) : (
          <HeaderGuest />
        )}

        <Layout
          className={styles.siteLayout}
          style={
            width > 800 ? { marginTop: !_.isEmpty(user) ? "3rem" : "0" } : {}
          }
        >
          <Content
            style={{
              margin: width > 800 ? "4rem 5rem" : "4rem 0px",
              overflow: "initial",
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <div
              className={styles.divChildren}
              style={{ padding: width > 800 ? "24px 0" : "0" }}
            >
              {props.children}
            </div>
          </Content>
        </Layout>
      </Layout>
      <Footer props={props.children.props} />
      {user || acceptCookie
        ? ""
        : !getCookie("acceptCookie") && (
            <div className={styles.navbarPDPA}>
              <div>
                <span>
                  Kengsob มีการใช้คุ้กกี้เพื่อประสอบการการใช้งานที่ดีขึ้น{" "}
                </span>
                {/* <Link to="/privacy-policy">
                  <span className={styles.linkAccept}>
                    อ่านนโยบายความเป็นส่วนตัว
                  </span>
                </Link> */}
              </div>
              <Button
                onClick={handleAcceptCookie}
                type="primary"
                size={"small"}
              >
                ยอมรับ
              </Button>
            </div>
          )}
    </div>
  );
};
export default LayoutCMS;
