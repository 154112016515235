import React, { useState, useRef } from "react";
import BundleDefault from "assets/icons/A4.svg";
import {
  HeartOutlined,
  ShareAltOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { patchFavoriteBundle, patchUnfavoriteBundle } from "api/bundle";
import ShareModal from "./BundleShare";
import { Button, Rate } from "antd";
import styles from "./Card.module.scss";
import { FaHeart } from "react-icons/fa";

const Card = ({ bundleItem, i, handleClickCard, refetch }) => {
  const [visibleHover, setVisibleHover] = useState(false);
  const [displayShareModal, setDisplayShareModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const preventClickRef = useRef(false);

  const onClickLike = async (id) => {
    setIsLoading(true);
    preventClickRef.current = true;
    if (bundleItem.isFavorite) {
      await patchUnfavoriteBundle({ id: bundleItem.id });
    } else {
      await patchFavoriteBundle({ id: bundleItem.id });
    }
    refetch();
    setIsLoading(false);
  };

  const openShareModal = () => {
    setDisplayShareModal(true);
  };

  const closeShareModal = () => {
    setDisplayShareModal(false);
  };

  const onClickShare = (event) => {
    preventClickRef.current = true;
    event.stopPropagation();
    setDisplayShareModal((prev) => !prev);
  };

  const handleCardClick = () => {
    if (preventClickRef.current) {
      preventClickRef.current = false;
      return;
    }
    handleClickCard(bundleItem.id);
  };

  return (
    <div
      className={styles.bundle_card}
      key={i}
      onClick={handleCardClick}
      onMouseEnter={() => setVisibleHover(true)}
      onMouseLeave={() => setVisibleHover(false)}
    >
      {visibleHover && (
        <div className={styles.bundle_card_banner_hover}>
          {isLoading ? (
            <LoadingOutlined />
          ) : bundleItem.isFavorite ? (
            <FaHeart
              className={styles.icon}
              onClick={onClickLike}
              style={{ color: "#ee5294" }}
            />
          ) : (
            <HeartOutlined className={styles.icon} onClick={onClickLike} />
          )}
          <ShareAltOutlined className={styles.icon} onClick={onClickShare} />
        </div>
      )}
      <div className={styles.bundle_card_banner}>
        <div className={styles.bundle_banner}>
          {!bundleItem.banner ? (
            <img
              src={bundleItem.cover}
              alt={bundleItem.name}
              className={styles.bundle_card_banner_img}
            />
          ) : (
            <img
              src={BundleDefault}
              alt="default"
              className={styles.bundle_card_banner_img}
            />
          )}
        </div>
      </div>
      <div className={styles.detail}>
        <div className={styles.bundle_card_detail}>
          <div className={styles.bundle_card_detail_publisher}>
            {bundleItem.publisher}
          </div>
          <div className={styles.bundle_card_detail_rating}>
            <div className={styles.bundle_card_detail_rating_amount}>
              {Math.floor(bundleItem.review.reviewPoint * 2) / 2}
            </div>
            <Rate
              value={bundleItem.review.reviewPoint}
              allowHalf
              disabled
              style={{ fontSize: ".75rem", marginLeft: "0.5rem" }}
            />
          </div>
        </div>
        <div className={styles.bundle_card_button}>
          <Button
            type="primary"
            className={styles.bundle_card_button_buy}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {bundleItem.isPurchase
              ? "เริ่มทำข้อสอบ"
              : bundleItem.price > 0
              ? `฿ ${bundleItem.price}`
              : "ฟรี"}
          </Button>
        </div>
      </div>
      <ShareModal
        displayShareModal={displayShareModal}
        openShareModal={openShareModal}
        closeShareModal={closeShareModal}
        url={bundleItem.linkBundle}
      />
    </div>
  );
};

export default Card;
