import React from 'react'
import { HeaderCMS, HeaderGuest } from 'components/Header'
import Footer from 'components/Footer/Footer'
import styles from './LayoutLoginAndRegister.module.scss'
import { Layout } from 'antd'
import useAuth from 'context/auth'
const { Content } = Layout
const LayoutLoginAndRegister = (props) => {
  const { state: { user, isAuthenticated }} = useAuth()
  return(
    <div className = {styles.content}>
      <Layout className = {styles.divLayout}>
        { isAuthenticated ?  <HeaderCMS {...props.children.props} user={user} /> : <HeaderGuest /> }
        <Content className='site-layout' style={{ marginTop: 64, backgroundColor : 'transparent' }}>
          <div className='site-layout-background' style={{ padding: 24, minHeight: 380 }}>
            {props.children}
          </div>
        </Content>
      </Layout>
      <Footer props={props.children.props} className="footer"/>
    </div>

  )
}
export default LayoutLoginAndRegister