import { Button, Form, Select } from 'antd'
import TitleAndBreadCrumb from 'components/TitleAndBreadCrumb/TitleAndBreadCrumb'
import React, { useState, useEffect } from 'react'
import styles from './Language.module.scss'
import { TranslateLanguage } from 'util/Translate'
import { vocabLanguage } from 'util/Translate.variable'
import { editProfileService } from 'api/auth'
import { setLocalStorage } from 'util/localstorage'

const translate = new TranslateLanguage()
translate.addMaping(vocabLanguage)

function Language(props) {
  const goBack = () => props.history.push('/profile')
  const [state, setState] = useState({
    value: 'thai',
    loading: false,
  })

  useEffect(() => {

  }, [state])

  const handleClick = () => {
    setState((prev) => { return { ...prev, loading: true } })
    if (state.value === 'thai') {
      editProfileService({ language: 'th' })
      setLocalStorage('_lang', 'th')
      window.location.reload()
    }
    if (state.value === 'english') {
      setLocalStorage('_lang', 'en')
      editProfileService({ language: 'en' })
      window.location.reload()
    }
    setState((prev) => { return { ...prev, loading: false } })
  }

  return (
    <div>
      <TitleAndBreadCrumb
        path={{
          history: [
            {
              textGoBack: translate.convert('ข้อมูลผู้ใช้งาน'),
              text: translate.convert('Language'),
              goBack: goBack,
            }
          ]
        }}
        title={{
          text: translate.convert('Language')
        }}
      />
      <div className={styles.rootLgForm}>
        <h1 >{translate.convert('เลือกภาษา')}</h1>
        <Form.Item
          name='selectLanguage'
          className={styles.formItem}
        >
          <Select
            onChange={(value) => {
              setState((prev) => { return { ...prev, value } })
            }}
            defaultValue={'thai'}
            className={styles.selectLanguage}
            options={[
              { value: 'thai', label: 'ภาษาไทย' },
              { value: 'english', label: 'English' },
            ]}
          >
          </Select>
        </Form.Item>
        <Button
          loading={state.loading === true}
          onClick={handleClick}
          className={styles.confirm} type='primary'>
          {translate.convert('ตกลง')}</Button>
      </div>
    </div>
  )
}

export default Language