import axios, { handleRequest } from "./kiddy-connector";
import { dateMonthYear } from "util/helper";

const parseResponseExam = (response) => {
  return response.list.map((exam) => {
    exam.createdAt = dateMonthYear(exam?.createdAt * 1000);
    return exam;
  });
};

const parseIsPkgFree = (response) => {
  return response.list.map((exam) => {
    exam.isFree = true;
    return exam;
  });
};

const GetExamAPI = (params) =>
  axios.get("/exams", { params }).then(parseResponseExam);
const GetExamDetailAPI = (id) => axios.get(`/exams/${id}`);
const SearchExamAPI = (params) =>
  axios.get("/exams/searchExam", { params }).then(parseResponseExam);
const GetSuggestionExamAPI = (params) =>
  axios.get("/exams/suggestion", { params }).then(parseIsPkgFree);

const GetNewSuggestionExamAPI = () =>
  axios.get("/exams/suggestion/V2").then((res) => res.list);

const GetMyExamAPI = (params) =>
  axios.get("/group/teacher/listMyExam", { params }).then(parseResponseExam);
const GetNewMyExamAPI = () =>
  axios.get("/group/teacher/newListMyExam").then(parseResponseExam);
const TeacherPinMyExamAPI = (examId) =>
  axios.patch(`group/teacher/pinExam/${examId}`, {});
const TeacherUnpinMyExamAPI = (examId) =>
  axios.patch(`group/teacher/unpinExam/${examId}`, {});
const TeacherBookmarkMyExamAPI = (examId) =>
  axios.patch(`group/teacher/bookmarkExam/${examId}`, {});
const TeacherUnbookmarkMyExamAPI = (examId) =>
  axios.patch(`group/teacher/unbookmarkExam/${examId}`, {});
const BookmarkExamAPI = (examId) => axios.patch(`exams/bookmark/${examId}`, {});
const ReportExamAPI = (data) => axios.post("/exams/report", data);
const GetListFreeExamAPI = () =>
  axios.get("/exams/freeExam").then((res) => res.list);
const GetSubject = (params) =>
  axios.get(`master/subjects?level=${params}`).then((res) => res.list);
const GetPublisher = () =>
  axios.get("/master/publisher").then((res) => res.list);

export const getExamService = (params) => handleRequest(GetExamAPI(params));
export const getExamDetailService = (id) => handleRequest(GetExamDetailAPI(id));
export const searchExamService = (params) =>
  handleRequest(SearchExamAPI(params));
export const getSuggestionExamService = (params) =>
  handleRequest(GetSuggestionExamAPI(params));
export const getMyExamService = (params) => handleRequest(GetMyExamAPI(params));
export const getNewMyExamService = () => handleRequest(GetNewMyExamAPI());
export const teacherPinMyExamService = (examId) =>
  handleRequest(TeacherPinMyExamAPI(examId));
export const teacherUnpinMyExamService = (examId) =>
  handleRequest(TeacherUnpinMyExamAPI(examId));
export const teacherBookmarkMyExamService = (examId) =>
  handleRequest(TeacherBookmarkMyExamAPI(examId));
export const teacherUnbookmarkMyExamService = (examId) =>
  handleRequest(TeacherUnbookmarkMyExamAPI(examId));
export const bookmarkExamService = (examId) =>
  handleRequest(BookmarkExamAPI(examId));
export const reportExam = (data) => handleRequest(ReportExamAPI(data));
export const ExamSuggestionListService = (level) =>
  handleRequest(ExamSuggestionListService(level));
export const GetListFreeExamService = () => handleRequest(GetListFreeExamAPI());
export const getSubject = (params = "") => handleRequest(GetSubject(params));
export const getPublisher = () => handleRequest(GetPublisher());

export const getNewSuggestionExamService = () =>
  handleRequest(GetNewSuggestionExamAPI());
