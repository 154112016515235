// import React from 'react'
// import { resTypeOfAnswer } from 'services/testing/action/typeofAnswer'
/* eslint-disable */

import dayjs from "dayjs";

const setLocalStorage = (key, value) =>
  localStorage.setItem(key, JSON.stringify(value));
const clearLocalStorage = (key) => localStorage.removeItem(key);
const getLocalStorage = (key) => {
  const value = localStorage.getItem(key);
  if (!value) return null;
  try {
    return JSON.parse(value);
  } catch (error) {
    return null;
  }
};

////////////////////////  Set Exam ///////////////////////////////

export const localDataExam = {
  set: ({ key, examId, groupId, userId, data, typeExam }) => {
    const oldData = localDataExam.get(key);
    if (oldData) {
      return localStorage.setItem(
        key,
        JSON.stringify({ ...oldData, examId, groupId, userId, data, typeExam })
      );
    }
    localStorage.setItem(
      key,
      JSON.stringify({ examId, groupId, userId, data, typeExam })
    );
  },
  setQt: (keyLocal, dataQuestion) => {
    const oldData = localDataExam.get(keyLocal);
    localStorage.setItem(
      keyLocal,
      JSON.stringify({ ...oldData, dataQuestion })
    );
  },
  setAns: (keyLocal, answer) => {
    const present = dayjs().unix();
    const oldData = localDataExam.get(keyLocal);
    setLocalStorage(keyLocal, {
      ...oldData,
      dataAns: { answer: answer, testingTime: present - oldData.startAt },
    });
  },
  setPretest: ({
    keyLocal,
    data,
    typeExam = "pretestExam",
    startDate = null,
  } = {}) => {
    const oldData = localDataExam.get(keyLocal);
    localStorage.setItem(
      keyLocal,
      JSON.stringify({ ...oldData, data, typeExam, startDate })
    );
  },
  setCurrent: (keyLocal, current) => {
    const oldData = localDataExam.get(keyLocal);
    localStorage.setItem(keyLocal, JSON.stringify({ ...oldData, current }));
  },
  setSartTime: (keyLocal, startAt) => {
    const oldData = localDataExam.get(keyLocal);
    localStorage.setItem(keyLocal, JSON.stringify({ ...oldData, startAt }));
  },
  get: (keyLocal, props) => {
    if (getLocalStorage(keyLocal) !== null) {
      if (props) return getLocalStorage(keyLocal).props;
      return getLocalStorage(keyLocal);
    }
    return false;
  },
  clear: (keyLocal) => clearLocalStorage(keyLocal),
};

////////////////////////////////////  Set LocalStorage When enter Testing  ////////////////////////////////////////////////////

export const setLocalExam = ({ current, props, user, data } = {}) => {
  if (current) {
    localStorage.setItem("current", current);
  }
  // ข้อสอบประเภทกลุ่ม
  if (!data) {
    data = { examDetail: "" };
  }
  if (props?.typeExam === "groupExam") {
    setGroupLocal(props, data, user);
  } else if (props?.typeExam === "randomExam") {
    setRandomLocal(props, data, user);
  } else if (props?.typeExam === "generalExam") {
    setGNLocal(props, data, user);
  } else if (props?.typeExam === "pretestExam") {
    setPretestLocal(props, data, user);
  } else {
    setGNLocal(props, data, user);
  }
};

const setGroupLocal = (props, data, user) => {
  const Exam = localDataExam.get(props?.keyLocal)?.data;
  if (!Exam) {
    return localDataExam.set({
      key: props.keyLocal,
      examId: props.data?.id || data?.id,
      groupId: props?.groupId,
      userId: user?.id,
      data: data,
      typeExam: "groupExam",
    });
  }
};

const setRandomLocal = (props, data, user) => {
  const Exam = localDataExam.get(props?.keyLocal)?.data;
  if (!Exam) {
    return localDataExam.set({
      key: props.keyLocal,
      userId: user?.id,
      data: data,
      typeExam: "randomExam",
    });
  }
};

const setPretestLocal = (props, data, user) => {
  const Exam = localDataExam.get(props?.keyLocal)?.data;
  if (!Exam) {
    return localDataExam.set({
      key: props.keyLocal,
      userId: user?.id,
      data: data,
      typeExam: "pretestExam",
    });
  }
};

const setGNLocal = (props, data, user) => {
  const Exam = localDataExam.get(props?.keyLocal)?.data;
  if (!Exam) {
    return localDataExam.set({
      key: props.keyLocal,
      examId: data?.id,
      userId: user?.id,
      data: data,
      typeExam: "generalExam",
    });
  }
};

//////////////////////   Clear LocalStorage  of Testing  //////////////////////////

export const CLSTesting = () => {
  localStorage.removeItem("dataStartTesting");
  localStorage.removeItem("dataQuestion");
  localStorage.removeItem("dataExam");
  localStorage.removeItem("dataAnswer");
  localStorage.removeItem("TimeStart");
  localStorage.removeItem("TimeEnd");
  localStorage.removeItem("start");
  localStorage.removeItem("testingTime");
  localStorage.removeItem("startAt");
  localStorage.removeItem("currentTime");
  localStorage.removeItem("current");
  localStorage.removeItem("startTesting");
  // error function
  localStorage.removeItem("");
  localStorage.removeItem("undefined");
  // typeExam
  localStorage.removeItem("generalExam");
  localStorage.removeItem("randomExam");
  localStorage.removeItem("pretestExam");
};

export const clearLSTesting = (keyLocal) => {
  CLSTesting();
  if (keyLocal) {
    localStorage.removeItem(keyLocal);
  }
};
