import React, { useEffect, useState } from "react";
import styles from "./myExamination.module.scss";
import useWindowSize from "hook/useWindowSize";
import TitleAndBreadCrumb from "components/TitleAndBreadCrumb/TitleAndBreadCrumb";
import { TranslateLanguage } from "util/Translate";
import { useDataStore } from "store/DataStoreContext";
import { actionType } from "store/dataReducer";
import { getMyBundle } from "api/bundle";
import { Empty, Spin } from "antd";
import _ from "lodash";
import Card from "pages/Home/Components/BundleCard/Card";
import TableBundle from "pages/Home/Components/BundleCard/TableBundle";
import ImgNoResult from "assets/images/no-result.svg";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { getMyPretestService } from "api/pretest";
import TablePretest from "components/Card/Pretest/TablePretest";
import CardPretest from "components/Card/Pretest/CardPretest";

const translate = new TranslateLanguage();

const MyExamination = (props) => {
  const { width } = useWindowSize();
  const {
    state: { bundle, myExam, displayType },
    dispatch,
  } = useDataStore();

  const [typeTab, setTypeTab] = useState("bundle");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initMyExamination();
  }, [typeTab]);

  const initMyExamination = async () => {
    setLoading(true);
    if (typeTab === "bundle") {
      await getMyBundle().then((res) => {
        dispatch({
          type: actionType.FETCH_MY_EXAM,
          payload: { bundle: res },
        });
      });
    } else {
      await getMyPretestService().then((res) => {
        dispatch({
          type: actionType.FETCH_MY_EXAM,
          payload: { pretest: res },
        });
      });
    }
    setLoading(false);
  };

  const handleChangeMode = (value) => {
    dispatch({ type: actionType.CHANGE_DISPLAY_TYPE, payload: value });
  };

  const handleClickCard = (id) => {
    props.history.push(`/bundle/detail`, { bundleId: id });
  };

  const onClickCard = (pretest, e) => {
    if (
      e?.target.tagName.toLowerCase() === "span" ||
      e?.target.tagName.toLowerCase() === "button"
    ) {
      return;
    }
    if (pretest.id !== "a6f2678a-df43-4d30-98e5-a9dbcb36ec77") {
      return props.history.push(`/pretest/${pretest.id}`);
    }
  };

  return (
    <div className={styles.main} style={width > 1100 ? {} : { margin: "15px" }}>
      <TitleAndBreadCrumb
        path={{
          history: [
            {
              textGoBack: translate.convert("หน้าแรก"),
              text: translate.convert("ข้อสอบของฉัน"),
              goBack: () => props.history.push("/"),
            },
          ],
        }}
        title={{ text: translate.convert("ข้อสอบของฉัน") }}
        option={{
          options: {
            mode: {
              value: bundle.displayType,
              onChange: handleChangeMode,
            },
          },
        }}
      />
      <div className={styles.button_con}>
        <Tabs>
          <TabList>
            <Tab
              onClick={() => setTypeTab("bundle")}
              style={typeTab === "bundle" ? { color: "#ee5294" } : {}}
            >
              Bundle
            </Tab>
            <Tab
              onClick={() => setTypeTab("pretest")}
              style={typeTab === "pretest" ? { color: "#ee5294" } : {}}
            >
              Pretest
            </Tab>
          </TabList>
        </Tabs>
      </div>
      {typeTab === "bundle" ? (
        <div className={styles.container}>
          {!loading ? (
            _.size(myExam.bundle.data) > 0 ? (
              myExam.bundle.data.map((item, id) =>
                displayType === "card" ? (
                  <Card
                    bundleItem={item}
                    key={id}
                    handleClickCard={handleClickCard}
                  />
                ) : (
                  <TableBundle
                    bundleItem={item}
                    key={id}
                    handleClickCard={handleClickCard}
                  />
                )
              )
            ) : (
              <div style={{ textAlign: "center", margin: "20% auto" }}>
                <img src={ImgNoResult} alt="ImgNoResult" />
                <div
                  clazssName={styles.text_no_result}
                  style={{ color: "#999899" }}
                >
                  ไม่พบข้อมูล
                </div>
              </div>
            )
          ) : (
            <Spin size="large" style={{ margin: "20% auto" }} />
          )}
        </div>
      ) : (
        <div
          className={styles.pretest_container}
          style={
            displayType === "card" && _.size(myExam.pretest.data) > 0
              ? {
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: "2rem",
                  justifyItems: "stretch",
                }
              : {}
          }
        >
          {!loading ? (
            _.size(myExam.pretest.data) > 0 ? (
              myExam.pretest.data.map((item, id) =>
                displayType === "card" ? (
                  <CardPretest
                    history={props.history}
                    key={id}
                    pretest={item}
                    // fetchListPretests={fetchListPretests}
                    onClickCard={() => onClickCard(item)}
                  />
                ) : (
                  <TablePretest
                    key={id}
                    history={props.history}
                    pretest={item}
                    // fetchListPretests={fetchListPretests}
                    onClickCard={() => onClickCard(item)}
                  />
                )
              )
            ) : (
              <div style={{ textAlign: "center", margin: "20% auto" }}>
                <img src={ImgNoResult} alt="ImgNoResult" />
                <div
                  clazssName={styles.text_no_result}
                  style={{ color: "#999899" }}
                >
                  ไม่พบข้อมูล
                </div>
              </div>
            )
          ) : (
            <Spin
              size="large"
              style={{
                display: "flex",
                margin: "20% auto",
                justifyContent: "center",
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MyExamination;
