import React, { useState, useEffect, useRef } from "react";
import styles from "./TablePretest.module.scss";
import IconList from "assets/images/subject/icons-list-cu.png";
import IconStar from "assets/images/subject/icons-point-cu.png";
import IconTime from "assets/images/subject/icons-time-cu.png";
import IconCarlendar from "assets/images/subject/icon-calendar-cu.svg";
import { message, Button } from "antd";
import { TranslateLanguage } from "util/Translate";
import { vocabPretest } from "util/Translate.variable";
import useAuth from "context/auth";
import { PretestStatus } from "util/helper";
import { textPretestButton } from "pages/Pretest/PretestDetail/PretestButton";
import { getDatabase, ref, onValue } from "firebase/database";
import {
  getFreePretestService,
  unfavoritePretestService,
  favoritePretestService,
} from "api/pretest";
import { getProfileService } from "api";
import { actionType } from "reducers/auth";
import moment from "moment";
import useInterval from "util/useInterval";
import { ShareAltOutlined, LoadingOutlined } from "@ant-design/icons";
import { IoHeartOutline, IoHeartSharp } from "react-icons/io5";
import ShareModal from "pages/Home/Components/BundleCard/BundleShare";
import { useDataStore } from "store/DataStoreContext";

const translate = new TranslateLanguage();
translate.addMaping(vocabPretest);

const PretestTable = ({ history, pretest, onClickCard, fetchListPretests }) => {
  const {
    state: { user },
    dispatch,
  } = useAuth();
  const { fetchPretest } = useDataStore();

  const preventClickRef = useRef(false);

  const [pretestFirebaseData, setPretestFirebaseData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [saleTime, setSaleTime] = useState();
  const { AllStatus } = PretestStatus(pretest, pretestFirebaseData, user);
  const [isHovered, setIsHovered] = useState();
  const [loading, setLoading] = useState(false);
  const [displayShareModal, setDisplayShareModal] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const subscribeFirebaseData = async () => {
        setIsLoading(true);
        const db = getDatabase();
        if (pretest?.firebaseUrl) {
          const response = await ref(db, pretest.firebaseUrl);
          onValue(response, (snap) => {
            setPretestFirebaseData(snap.val());
            setIsLoading(false);
          });
        } else {
          setIsLoading(false);
        }
        return null;
      };
      subscribeFirebaseData();
    }
    return () => {
      isMounted = false;
      localStorage.removeItem("now");
      localStorage.removeItem("isSale");
    };
  }, []);

  const handleClickBuuton = async () => {
    if (isLoading) {
      return "";
    }
    if (pretestFirebaseData) {
      const doPretest = AllStatus.find(
        (status) =>
          status === "passTesting:2" ||
          status === "passTesting:false" ||
          status === "passTesting:true"
      );
      if (doPretest) {
        return history.push(`/pretest/${pretest.id}`);
      }
      const purchased = AllStatus.find(
        (stutus) =>
          stutus === "isPurchased:0" ||
          stutus === "isPurchased:1" ||
          stutus === "isPurchased:2"
      );
      if (purchased) {
        return history.push(`/pretest/${pretest.id}`);
      }
      if (pretest.price.cash) {
        return history.push({
          pathname: "/payment",
          state: { type: "pretest", data: pretest },
          search: "typeExam=pretestExam",
        });
      }
      if (pretest?.price.cashPriceTier === "free") {
        setIsLoading(true);
        const res = await getFreePretestService({
          pretestId: pretest.id,
          paymentType: "creditCard",
          currency: "cash",
        });
        if (res) {
          if (res?.message) {
            message.success(res.message);
          }
          const payload = await getProfileService();
          fetchListPretests();
          if (payload) {
            dispatch({ type: actionType.LOAD_PROFILE, user: payload });
          }
          setIsLoading(false);
        }
        if (res.error) {
          message.error(
            "ไม่สามารถทำข้อสอบชุดนี้ได้ กรุณาแจ้งเจ้าหน้าที่ดูแลระบบ"
          );
          setIsLoading(false);
          return;
        }
        return;
      }
    }
    return "";
  };

  const countUp = () => {
    const lowesSaleDate = [];
    if (!pretest.isSale) {
      localStorage.setItem("now", parseInt(moment().valueOf() / 1000));
      setSaleTime(parseInt(localStorage.getItem("now")) || null);
      lowesSaleDate.push(pretest?.saleDate);
      if (lowesSaleDate.length !== 0) {
        if (Math.min(...lowesSaleDate) < parseInt(moment().valueOf() / 1000)) {
        }
      }
    }
  };
  useInterval(countUp, 1000);

  const isDisableActionBtn = () => {
    if (!pretest || !AllStatus) {
      return true;
    }
    const tested = JSON.parse(localStorage.getItem(pretest?.id));
    if (pretest.continuePretest === true || tested) {
      return false;
    }
    return (
      (AllStatus && AllStatus.includes("waitSale")) ||
      AllStatus.includes("isPurchased:0") ||
      AllStatus.includes("practiceTime:off")
    );
  };

  function formatTimestampRange(timestamps) {
    function formatDate(timestamp) {
      const date = new Date(timestamp * 1000);
      const day = date.getDate();
      const options = {
        year: "numeric",
        month: "short",
        timeZone: "Asia/Bangkok",
      };
      const formattedDate = date.toLocaleDateString("th-TH", options);
      const formattedTime = date.toLocaleTimeString("th-TH", {
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Asia/Bangkok",
      });
      return `${day} ${formattedDate}, ${formattedTime}`;
    }

    const formattedStart = formatDate(timestamps.start);
    const formattedEnd = formatDate(timestamps.end);
    return `${formattedStart} - ${formattedEnd}`;
  }

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const onClickLike = async () => {
    setLoading(true);
    preventClickRef.current = true;
    if (pretest.isFavorite) {
      await unfavoritePretestService({ id: pretest.id });
    } else {
      await favoritePretestService({ id: pretest.id });
    }
    fetchPretest();
    setIsLoading(false);
    setLoading(false);
  };

  const openShareModal = () => {
    setDisplayShareModal(true);
  };

  const closeShareModal = () => {
    setDisplayShareModal(false);
  };

  const onClickShare = (event) => {
    preventClickRef.current = true;
    event.stopPropagation();
    setDisplayShareModal((prev) => !prev);
  };

  const handleClickCard = () => {
    if (preventClickRef.current) {
      preventClickRef.current = false;
      return;
    }
    onClickCard();
  };

  return (
    <div
      className={isHovered ? styles.hovered : ""}
      onClick={handleClickCard}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.pretest_table_container}>
        <div className={styles.table_left}>
          <img
            className={styles.table_banner}
            alt="banner"
            src={pretest.banner}
          />
        </div>
        <div className={styles.table_midle}>
          <div className={styles.up}>
            <div className={styles.table_name}>{pretest.name}</div>
            <div className={styles.div_publisher}>
              <div className={styles.prestest_host}>{pretest.host.name}</div>
              <div className={styles.pretest_vertical} />
              <div className={styles.publisher}>{pretest.writer}</div>
            </div>
          </div>
          <div className={styles.mid}>
            <div className={styles.item}>
              <img alt="IconList" src={IconList} className={styles.stylelist} />
              <span className={styles.styleamount}>
                {pretest.amountQuestions}
                <span style={{ marginLeft: "8px" }}>
                  {translate.convert("ข้อ")}
                </span>
              </span>
            </div>
            <div className={styles.score}>
              <img alt="IconStar" src={IconStar} className={styles.stylelist} />
              <span className={styles.styleamount}>
                {pretest.totalScore}
                <span style={{ marginLeft: "8px" }}>
                  {translate.convert("คะแนน")}
                </span>
              </span>
            </div>
            <div className={styles.time}>
              <img alt="IconTime" src={IconTime} className={styles.stylelist} />
              <span className={styles.styleamount}>
                {pretest.pretestTime}
                <span style={{ marginLeft: "8px" }}>
                  {translate.convert("นาที")}
                </span>
              </span>
            </div>
          </div>
          <div className={styles.date}>
            <img
              alt="IconCarlendar"
              src={IconCarlendar}
              className={styles.stylelist}
            />
            {/* <span style={{ marginLeft: "8px" }}> */}
            <span
              style={
                AllStatus.includes("waitSale")
                  ? { color: "red", marginLeft: "8px" }
                  : { marginLeft: "8px" }
              }
            >
              {formatTimestampRange(pretest.durationPretest)}
            </span>
          </div>
          <div className={styles.table_right}>
            {pretestFirebaseData && (
              <div
                className={`${
                  AllStatus.includes("waitSale") &&
                  AllStatus.includes("isSaleTime:0")
                    ? styles.wrapActionBtn
                    : styles.wrapActionBtnUp
                }`}
              >
                <Button
                  className={styles.btnCard}
                  type="primary"
                  onClick={() => handleClickBuuton()}
                  disabled={isDisableActionBtn()}
                >
                  {AllStatus && textPretestButton(pretest, AllStatus)}
                </Button>
              </div>
            )}
            <div className={styles.action_button}>
              <Button className={styles.button} onClick={() => onClickLike()}>
                {loading ? (
                  <LoadingOutlined />
                ) : pretest.isFavorite ? (
                  <IoHeartSharp
                    className={styles.icon}
                    style={{
                      color: "#ee5294",
                    }}
                  />
                ) : (
                  <IoHeartOutline className={styles.icon} />
                )}
              </Button>
              <Button className={styles.button} onClick={onClickShare}>
                <ShareAltOutlined />
              </Button>
            </div>
          </div>
        </div>
      </div>

      <hr
        className={styles.horizontal_line}
        style={{
          borderTop: "0px solid #999899",
        }}
      />
      <ShareModal
        displayShareModal={displayShareModal}
        openShareModal={openShareModal}
        closeShareModal={closeShareModal}
        url={pretest.linkPretest}
      />
    </div>
  );
};

export default PretestTable;
