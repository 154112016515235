
import { message } from "antd"
import { isEmptyObj } from "util/helper"
import { clearLSTesting } from "util/setLocalStorageTesting"


// send message to serviceWorker https://stackoverflow.com/questions/50867104/get-serviceworkerglobalscopes-variable-from-window-context
// รับ message ทั้ง Front และ Back
const setState = {}
if (window.navigator?.serviceWorker) {
  window.navigator.serviceWorker.addEventListener('message', event => {
    if (event?.data?.data?.type === 'change_device_testing') {
      if (isEmptyObj(setState)) {
        const payload = event?.data
        setState.title = payload.notification?.title
        setState.body = payload.notification?.body
        clearLSTesting()
        message.success(setState.title, [5])
        const notificationOptions = {
          body: setState.body,
          icon: window.location.origin + '/Logo.png'
        }
        if (window?.document?.visibilityState === 'visible') {
          new Notification(payload.notification?.title, notificationOptions)
        }
        if (window.location.href.includes('/testing')) {
          setTimeout(() => {
            window.location.href = '/'
          }, 5000)
        }
      }
      return
    } else if (event.data === 'config') {
      const config = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID
      }
      return event.ports[0].postMessage(config)
    } else if (event?.data?.data && event?.data?.data?.type !== 'change_device_testing') {
      if (isEmptyObj(setState)) {
        const payload = event?.data
        setState.title = payload.notification?.title
        setState.body = payload.notification?.body
        message.success(setState.title, [5])
        const notificationOptions = {
          body: setState.body,
          icon: window.location.origin + '/Logo.png'
        }
        if (window?.document?.visibilityState === 'visible') {
          new Notification(payload.notification?.title, notificationOptions)
        }
      }
    }
  })
}