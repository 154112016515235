// ACTION TYPE
export const actionType = {
  LOGIN: 'auth/LOGIN',
  LOAD_PROFILE: 'auth/LOAD_PROFILE',
  LOGOUT: 'auth/LOGOUT'
}

// INITIAL STATE
export const initialState = {
  isAuthenticated: false,
  user: null,
  isLoading: true
}

// REDUCER
export const authReducer = (state = initialState, action) => {
  switch(action.type) {
    case actionType.LOGIN: 
      return { ...state, isAuthenticated: true, isLoading: false }
    case actionType.LOAD_PROFILE: 
      return { ...state, user: action.user, isLoading: false }
    case actionType.LOGOUT: 
      return { user: null, isAuthenticated: false, isLoading: false }
    default: throw new Error('Unknown action auth type')
  }
}