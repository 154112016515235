import React, { useState, useEffect } from "react";
import { Breadcrumb, Button, Avatar, Dropdown, Skeleton } from "antd";
import styles from "./TitleAndBreadCrumb.module.scss";
import IconTable from "assets/images/switch/icon-grid-selected.png";
import IconTableGray from "assets/images/switch/icon-grid.png";
import IconList from "assets/images/switch/icon-hamberger-selected.png";
import IconListGray from "assets/images/switch/icon-hanberger.png";
import iconSorting from "assets/icons/icon-sorting.svg";
import IconShare from "assets/icons/group/icons-share.svg";
import IconPlus from "assets/icons/button/icons-plus.svg";

import iconDropdown from "assets/icons/icon-dropdown-cu.svg";
import iconFilter from "assets/icons/icon-filter-reverse-cu.svg";

import MenuDropdown from "components/Menu/MenuDropdown";
import useAuth from "context/auth";
import { editProfileService } from "api/auth";
import ButtonDownload from "components/Button/ButtonDownload";
import { PRIMARY_COLOR, USER_ROLE, PRIMARY_700_CU_COLOR } from "util/config";
import { useDataStore } from "store/DataStoreContext";

const TitleAndBreadCrumb = ({ path, title, option, optionDownload, style }) => {
  const {
    state: { user, isAuthenticated },
  } = useAuth();
  const {
    state: { displayType },
  } = useDataStore();
  const [modeType, setModeType] = useState(displayType);
  useEffect(() => {
    if (isAuthenticated) {
      if (option?.options?.mode?.defaultMode) {
        return setModeType(option.options?.mode?.defaultMode);
      }
    }
  }, []);
  const titleText = () => {
    const { type = "", text = "" } = title;
    switch (type) {
      case "secondary":
        return (
          <div className={styles.TitleRegRootSecond}>
            <h2 className={styles.txtTitleSecondary}>{text}</h2>
          </div>
        );
      case "modal":
        return (
          <div className={styles.TitleRegRootModal}>
            <h2 className={styles.txtTitleModal}>{text}</h2>
          </div>
        );
      case "small":
        return (
          <div className={styles.TitleRegRootModal}>
            <span className={styles.txtTitleModal} style={{ color: "#737373" }}>
              {text}
            </span>
          </div>
        );
      case "loading":
        return (
          <div className={styles.TitleRegRootModal}>
            <span className={styles.txtTitleModal} style={{ color: "#737373" }}>
              <Skeleton.Input active style={{ margin: "0 0 .75rem 0" }} />
            </span>
          </div>
        );
      default:
        return (
          <div className={styles.TitleRegRoot}>
            <h2 className={styles.txtTitle}>{text}</h2>
          </div>
        );
    }
  };
  const breadcrumb = () => {
    const { history = null } = path;

    const breadcrumbItems = (data, index) => {
      return (
        <Breadcrumb.Item key={index} onClick={data.goBack}>
          <span
            style={{
              color: PRIMARY_700_CU_COLOR,
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {data.textGoBack}
          </span>
        </Breadcrumb.Item>
      );
    };
    return (
      <Breadcrumb separator=">">
        {history && history?.map((h, i) => breadcrumbItems(h, i))}
        <Breadcrumb.Item>{history[history.length - 1]?.text}</Breadcrumb.Item>
      </Breadcrumb>
    );
  };
  const moreOption = () => {
    const {
      sortOrFilter = null,
      button = null,
      mode = null,
      more,
    } = option.options;
    const handleChangeMode = async (type) => {
      mode.onChange(type);
      setModeType(type);
      if (isAuthenticated) {
        await editProfileService({ webView: type });
      }
    };
    return (
      <div
        style={
          {
            // display: 'flex',
            // borderRadius: "20px",
            // border: "1px solid #0A0D14",
          }
        }
      >
        {sortOrFilter && (
          <Dropdown
            overlay={MenuDropdown(sortOrFilter?.menu)}
            trigger={["click"]}
            placement="bottomRight"
            arrow
          >
            <div
              style={{
                color: PRIMARY_700_CU_COLOR,
                display: "flex",
                cursor: "pointer",
                margin: "auto",
                padding: "7px 7px 0 10px",
              }}
            >
              <div style={{ paddingRight: "5px" }}>
                <img
                  size={24}
                  src={
                    sortOrFilter?.type === "filter" ? iconFilter : iconSorting
                  }
                />
              </div>
              <span style={{ fontSize: "16px" }}>{sortOrFilter?.text}</span>
              <div style={{ paddingLeft: "5px" }}>
                <img size={24} src={iconDropdown} />
              </div>
            </div>
          </Dropdown>
        )}
        {button?.map((b, i) => {
          let icon = IconPlus;
          if (b?.icon === "share") {
            icon = IconShare;
          }
          return (
            <Button
              type="primary"
              key={i}
              className={styles.btnCreate}
              onClick={b.action}
            >
              <Avatar size={24} src={icon} />
              {b.text}
            </Button>
          );
        })}
        {mode && (
          <div className={styles.switchBtn}>
            <div className={styles.rootSwitch}>
              <div
                view-options-id={`view-table${
                  modeType === "table" ? ".active" : ""
                }`}
                className={
                  modeType === "table" ? styles.divBtnSelected : styles.divBtn
                }
                onClick={() => handleChangeMode("table")}
              >
                <img
                  alt="IconList"
                  src={modeType === "table" ? IconList : IconListGray}
                />
              </div>
              <div className={styles.divDevide}>
                <div className={styles.devide} />
              </div>
              <div
                view-options-id={`view-card${
                  modeType === "card" ? ".active" : ""
                }`}
                className={
                  modeType === "card" ? styles.divBtnSelected : styles.divBtn
                }
                onClick={() => handleChangeMode("card")}
              >
                <img
                  alt="IconTable"
                  src={modeType === "card" ? IconTable : IconTableGray}
                />
              </div>
            </div>
          </div>
        )}
        {more && (
          <span
            span-itemid={`span-${more?.text}`}
            className={styles.seeMore}
            onClick={more?.onClick}
          >
            {more?.text}
          </span>
        )}
      </div>
    );
  };
  return (
    <div className={styles.container} style={style}>
      {path?.history ? (
        <div>
          <div className={styles.divHead}>
            {breadcrumb()}
            <div className={styles.right}>
              <div className={styles.btnDownload}>
                {user?.role === USER_ROLE.TEACHER && optionDownload && (
                  <ButtonDownload
                    type={optionDownload.type}
                    props={optionDownload.props}
                    overAllExam={optionDownload.overAllExam}
                    popup={optionDownload.popup}
                    groupId={optionDownload.groupId}
                    examDetail={optionDownload.examDetail}
                    student={optionDownload.student}
                    examId={optionDownload.examId}
                  />
                )}
              </div>
              {option && moreOption()}
            </div>
          </div>
          {title && titleText()}
        </div>
      ) : (
        <div className={styles.divHead}>
          {title && titleText()}
          <div className={styles.right}>
            <div className={styles.btnDownload}>
              {user?.role === USER_ROLE.TEACHER && optionDownload && (
                <ButtonDownload
                  popup={optionDownload.popup}
                  type={optionDownload.type}
                  props={optionDownload.props}
                  overAllExam={optionDownload.overAllExam}
                  groupId={optionDownload.groupId}
                  examDetail={optionDownload.examDetail}
                  student={optionDownload.student}
                  examId={optionDownload.examId}
                />
              )}
            </div>
            {option && moreOption()}
          </div>
        </div>
      )}
    </div>
  );
};
export default TitleAndBreadCrumb;
