import React, { useState, useEffect, Fragment } from 'react'
import { Dropdown, Button, Menu, message } from 'antd'
import DownloadIcon from 'components/Icon/DownloadIcon'
import styles from './ButtonDownload.module.scss'
import IconReportExam from 'assets/icons/group/icon-report-exam.svg'
import ReactGA from 'react-ga'
import { propsButton } from 'util/handleButton'
import { TranslateLanguage } from 'util/Translate'
import { vocabButtonDownload } from 'util/Translate.variable'

const translate = new TranslateLanguage()
translate.addMaping(vocabButtonDownload)

const ButtonDownload = (props) => {
  const { onClickButtonDownload, type, popup } = props
  const [typeBtn, setTypeBtn] = useState('groupDetail')

  const menuGroup = (
    <Menu className={styles.divMenu} onClick={onClickButtonDownload} >
      <Menu.Item key='examReport'>
        <div className={styles.divItem}>
          <div className={styles.regTangle} />
          <div className={styles.divLabel}>
            <img alt='icon' src={IconReportExam} className={styles.icon} />
            <span className={styles.txtMenu}>{translate.convert('รายงานชุดข้อสอบ')}</span>
          </div>
        </div>
      </Menu.Item>
      <Menu.Item key='studentReport'>
        <div className={styles.divItem}>
          <div className={styles.regTangle} />
          <div className={styles.divLabel}>
            <img alt='icon' src={IconReportExam} className={styles.icon} />
            <span className={styles.txtMenu}>{translate.convert('รายงานนักเรียน')}</span>
          </div>
        </div>
      </Menu.Item>
    </Menu>
  )

  useEffect(() => {
    if (type !== 'groupDetail') {
      setTypeBtn('')
    }
  }, [])

  const sendGAEvent = (menu) => {
    if (menu) {
      ReactGA.event({
        category: 'ReportPDF',
        action: 'Click Download Report',
        label: menu
      })
    }
  }

  return (
    <Fragment>
      {
        typeBtn === '' ?
          <Button
            className={styles.btnNav}
            disabled={new propsButton('ButtonDownload', { props, value: type }).checkDisable()} id='downloadReport' type='primary' onClick={(e) => {
              e.stopPropagation()
              if (type === 'membersReport') {
                sendGAEvent('membersReport')
                if (!props.student?.canReport) {
                  return message.error(`${props.student.name} ${translate.convert('ไม่สามารถออกรายงานได้')}`)
                }
                const win = window.open(`/group/download/file/pdf?type=membersReport&members=${props.student.id}&groupId=${props.groupId}`, "_blank");
                win.focus()
              }
              if (type === 'historyReport') {
                sendGAEvent('historyReport')
                if (!props.overAllExam?.canReport) {
                  return message.error(`${translate.convert('ข้อสอบ')} ${props.examDetail.name} ${translate.convert('ไม่สามารถออกรายงานได้')}`)
                }
                const win = window.open(`/group/download/file/pdf?type=historyReport&groupId=${props.groupId}&exams=${props.examId}`, "_blank");
                win.focus()
              }
              if (type === 'myExamReport') {
                popup(true)
              }
            }}
            icon={<DownloadIcon />}>&nbsp; Download Report</Button>
          :
          <Dropdown overlay={menuGroup} placement='bottomRight' arrow trigger='click' >
            <Button
              className={styles.btnNav}
              id='downloadReport'
              type='primary'
              onClick={(e) => e.stopPropagation()}
              icon={<DownloadIcon />}>&nbsp; Download Report</Button>
          </Dropdown>
      }
    </Fragment>
  )
}

export default ButtonDownload

