import React from 'react'
import { Modal, Button } from 'antd'
import styles from './ModalSuccess.module.scss'
import IconSuccess from 'assets/icons/icon-success.svg'
import { TranslateLanguage } from 'util/Translate'
import { vocabModalSuccess } from 'util/Translate.variable'

const translate = new TranslateLanguage()
translate.addMaping(vocabModalSuccess)

const ModalSuccess = (props) => {
    const {
        visible,
        title = '',
        content = '',
        onClose,
    } = props
    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            footer={false}
            centered
        >
            <div className={styles.modal_success}>
                <img alt='IconSuccess' src={IconSuccess} />
                <br /><br />
                <h1>{title}</h1>
                <span>{content && content.includes('\n') ? content.split('\n').map((value) => <p> {value} </p>) : content} </span><br />
                <Button onClick={onClose} type='primary'>{translate.convert('ตกลง')}</Button>
            </div>
        </Modal >
    )
}
export default ModalSuccess