import LayoutExternal from 'Layout/Layout/LayoutExternal'
import React, { useEffect, useState } from 'react'
import qs from 'qs'
import styles from './VerifyEmail.module.scss'
import ModalSuccess from 'pages/Profile/Package/ModalSuccess'
import ModalWrong from 'components/Modal/ModalWrong'
import { TranslateLanguage } from 'util/Translate'
import { vocabVerifyEmail } from 'util/Translate.variable'
import validImage from 'assets/icons/icon_success_big.svg'
import failImage from 'assets/icons/payment-fail.svg'
import { Avatar } from 'antd'

const translate = new TranslateLanguage()
translate.addMaping(vocabVerifyEmail)

const MessageVerifyEmail = ({
  path = {},
  query = {},
}) => {
  if (query === 'false') {
    if (path === 'en') {
      return (
        <>
          <Avatar alt='success' src={failImage} className={styles.iconValid} />
          <p className={styles.textError}>Your email address verification was unsuccessful.</p>
        </>
      )
    }
    return (
      <>
        <Avatar alt='success' src={failImage} className={styles.iconValid} />
        <p className={styles.textError}>การยืนยันที่อยู่อีเมลของคุณไม่สำเร็จ </p>
      </>
    )
  }
  if (path === 'en') {
    return (
      <>
        <Avatar alt='success' src={validImage} className={styles.iconValid} />
        <p className={styles.textVerify}>Your email address has been verified successfully. You can now go back to the website or application for the junior sobtid</p>
      </>
    )
  }
  return (
    <>
      <Avatar alt='success' src={validImage} className={styles.iconValid} />
      <p className={styles.textVerify}>ยืนยันที่อยู่อีเมลของคุณสำเร็จแล้ว ตอนนี้คุณสามารถกลับไปที่เว็บไซต์หรือแอปพลิเคชันสอบติดจูเนียร์ได้แล้ว</p>
    </>
  )
}

export default function VerifyEmail() {
  const getPath = qs.parse(window.location.pathname.split('/')[2])
  const getQS = qs.parse(window.location.search.split('?')[1])
  const [visibleSuccess, setVisibleSuccess] = useState(false)
  const [visibleWrong, setVisibleWrong] = useState(false)

  useEffect(() => {
    if (getPath === 'en') {
      // setLocalStorage('_lang', 'en')
    }
  }, [getQS.success])

  return (
    <LayoutExternal>
      <div>
        <div className={styles.divTextToHome}>
          <MessageVerifyEmail path={Object.keys(getPath)[0]} query={getQS?.result} />
        </div>
        <ModalSuccess
          visible={visibleSuccess}
          setVisible={setVisibleSuccess}
          title={translate.convert('ผลการการยืนยัน Email ของคุณสำเร็จ')}
          setBody=''
        >
        </ModalSuccess>
        <ModalWrong
          visible={visibleWrong}
          setVisible={setVisibleWrong}
          title={translate.convert('ผลการการยืนยัน Email ของคุณไม่สำเร็จ')}
          setBody=''
        >
        </ModalWrong>
      </div>
    </LayoutExternal>
  )
}
