import React, { useState, useEffect } from 'react'
import { Button, Input, Form, Typography, message, Row } from 'antd'
import { submitForgotPassword } from 'api/auth'
// import Title from 'components/Title/Title'
import styles from '../ModalForgotPassword.module.scss'
import useInterval from 'util/useInterval'
import { TranslateLanguage } from 'util/Translate'
import { vocabConfirmStep } from 'util/Translate.variable'

const translate = new TranslateLanguage()
translate.addMaping(vocabConfirmStep)

const { Text } = Typography
const defaultTime = 60

function ConfirmStep({
  errorMessageWrongOTP,
  isLoading,
  verifyData,
  setVerifyData,
  verifyOTP,
  valuesInput
}) {
  const [limitOTP, setLimitOTP] = useState(defaultTime)

  const countUp = () => {
    if (parseInt(localStorage.getItem('limitOTP')) <= 0) {
      stopInterval()
    } else if (localStorage.getItem('limitOTP') && typeof parseInt(localStorage.getItem('limitOTP')) === 'number') {
      localStorage.setItem('limitOTP', parseInt(localStorage.getItem('limitOTP')) - 1)
      setLimitOTP(localStorage.getItem('limitOTP'))
    } else {
      localStorage.setItem('limitOTP', defaultTime)
    }
  }
  useEffect(() => {
    localStorage.removeItem('limitOTP')
    return () => {
      localStorage.removeItem('limitOTP')
    }
  }, [])

  const stopInterval = useInterval(countUp, 1000)

  const sendOTPAgain = async (values) => {
    var emailRegex = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    var phoneRegex = /^(\+91-|\+91|0)?\d{10}$/
    if (phoneRegex.test(valuesInput.email)) {
      const data = { phoneNumber: valuesInput.email }
      const res = await submitForgotPassword(data)
      if (res && !res.error) {
        verifyData['memberId'] = res.memberId
        setVerifyData(verifyData)
      }
    } else if (emailRegex.test(String(valuesInput.email).toLowerCase())) {
      const data = { email: valuesInput.email }
      const res = await submitForgotPassword(data)
      if (res && !res.error) {
        verifyData['memberId'] = res.memberId
        setVerifyData(verifyData)
      }
    }
    localStorage.setItem('limitOTP', defaultTime)
    message.info(translate.convert('ระบบส่งรหัส OTP ให้ใหม่ กรุณาตรวจสอบอีก อีเมล์ / เบอร์โทร ครั้ง'))
  }

  return (
    <div>
      <div>
        <h2>{translate.convert('ยืนยันตัวตน')}</h2>
      </div>
      <div className={styles.divStep}>
        <Form
          name='form_login'
          initialValues={{
            remember: true,
          }}
          className={styles.formLogin}
          onFinish={verifyOTP}
        >
          <Form.Item
            name='code'
            rules={[
              {
                required: true,
                message: translate.convert('กรุณากรอกรหัส OTP'),
              },
            ]}
          >
            <Input className='inputForm' placeholder={translate.convert('รหัส OTP')} />
          </Form.Item>
          <Row>
            {
              parseInt(limitOTP) <= 0 ?
                <span onClick={sendOTPAgain} style={{ color: '#11763b', float: 'left', marginRight: 20, cursor: 'pointer' }} >{translate.convert('ส่งรหัส OTP อีกครั้ง')}</span>
                :
                <span style={{ color: '#11763b', float: 'left', marginRight: 20 }}>{translate.convert('ยังไม่ได้รับรหัสผ่าน')}{limitOTP}  </span>
            }
            <span style={{ color: '#11763b', float: 'right', marginRight: 20 }}>{translate.convert('รหัส OTP มีอายุการใช้งาน 3 นาที')}</span>
          </Row>
          <Row>
            <Text type='danger'>{errorMessageWrongOTP}</Text>
          </Row>
          <Button type='primary' htmlType='submit' className={styles.btnLogin} loading={isLoading}>
            {translate.convert('ถัดไป')}
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default ConfirmStep