import {
  BLACK_COLOR,
  BROWNISH_GREY_COLOR,
  COOL_GREY_COLOR,
  DISABLE_COLOR,
  ICE_COLOR,
  LIGHT_BLUE_GREY,
  PRIMARY_COLOR,
  SECONDARY_COLOR, SLATE_GREY_COLOR,
  VERY_LIGHTPINK_COLOR,
  WARNING_COLOR,
  WRONG_COLOR,
  LINE_COLOR,
  BORDER_LINE,
  CORRECT_COLOR
} from "util/config"

if (document?.documentElement?.style) {
  document.documentElement.style.setProperty('--primaryColor', PRIMARY_COLOR)
  document.documentElement.style.setProperty('--secondaryColor', SECONDARY_COLOR)
  document.documentElement.style.setProperty('--shamrock', PRIMARY_COLOR)
  document.documentElement.style.setProperty('--dark-mint', SECONDARY_COLOR)
  document.documentElement.style.setProperty('--brown-grey', DISABLE_COLOR)
  document.documentElement.style.setProperty('--disable', DISABLE_COLOR)
  document.documentElement.style.setProperty('--very-light-pink', VERY_LIGHTPINK_COLOR)
  document.documentElement.style.setProperty('--brownish-grey', BROWNISH_GREY_COLOR)
  document.documentElement.style.setProperty('--ice', ICE_COLOR)
  document.documentElement.style.setProperty('--black', BLACK_COLOR)
  document.documentElement.style.setProperty('--light-blue-grey', LIGHT_BLUE_GREY)
  document.documentElement.style.setProperty('--cool-grey', COOL_GREY_COLOR)
  document.documentElement.style.setProperty('--slate-grey', SLATE_GREY_COLOR)
  document.documentElement.style.setProperty('--wrong', WRONG_COLOR)
  document.documentElement.style.setProperty('--warning', WARNING_COLOR)
  document.documentElement.style.setProperty('--line-color', LINE_COLOR)
  document.documentElement.style.setProperty('--border-line', BORDER_LINE)
  document.documentElement.style.setProperty('--correct', CORRECT_COLOR)
}
const groupExport = {
  primaryColor: PRIMARY_COLOR,
  secondaryColor: SECONDARY_COLOR,
  disableColor: DISABLE_COLOR,
  veryLightPinkColor: VERY_LIGHTPINK_COLOR,
  brownishGreyColor: BROWNISH_GREY_COLOR,
  iceColor: ICE_COLOR,
  blackColor: BLACK_COLOR,
  lightBlueGrey: LIGHT_BLUE_GREY,
  coolGreyColor: COOL_GREY_COLOR,
  slateGreyColor: SLATE_GREY_COLOR,
  wrongColor: WRONG_COLOR,
  warningColor: WARNING_COLOR,
  correctColor : CORRECT_COLOR,
}

export default groupExport