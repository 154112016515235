import { tab } from "@testing-library/user-event/dist/tab";
import _, { uniqBy } from "lodash";

export const filterSuggestExam = (suggest, level, number = 3) => {
  const filterByGrade = _.get(
    _.find(suggest, (each) => each.education === level),
    "tabBelow"
  );
  const allExams = _.flatMap(filterByGrade, (each) => each.exams);
  const shuffledExams = _.shuffle(allExams);

  return _.take(shuffledExams, number);
};

export const filteredSuggestInSuggest = (suggest, level) => {
  const result = suggest;

  return result;
};

export const filterSubject = ({ subjects, level }) => {
  const template = _.map(level, (level) => {
    return {
      id: level.id,
      name: level.name,
      subjectGroupObject: [],
    };
  });

  _.map(subjects, (subject) => {
    _.map(subject.level, (level) => {
      const levelId = level.id;

      const subjectGroupData = _.map(level.subjectGroup, (subGroup) => {
        const preparedSubject = {
          ...subject,
          comingSoon: subGroup.comingSoon,
        };

        return {
          id: subGroup.id,
          name: subGroup.name,
          subject: [preparedSubject],
        };
      });

      const foundTemplate = _.find(template, (temp) => temp.id === levelId);

      if (foundTemplate) {
        _.forEach(subjectGroupData, (newSubGroup) => {
          const existingGroup = _.find(
            foundTemplate.subjectGroupObject,
            (group) => group.id === newSubGroup.id
          );

          if (existingGroup) {
            existingGroup.subject = _.unionBy(
              existingGroup.subject.concat(newSubGroup.subject),
              "id"
            );
          } else {
            foundTemplate.subjectGroupObject.push(newSubGroup);
          }
        });
      }
    });
  });

  return template;
};

export const specialOrder = (subject) => {
  const ipstObject = _.get(
    _.find(subject, (sub) => sub.id === "PreUniversity"),
    "subjectGroupObject"
  );
  const lastItem = ipstObject.splice(-1)[0];
  ipstObject.unshift(lastItem);

  return subject;
};

export const filterBundleByFetch = (bundle, level) => {
  const result = _.filter(bundle, (obj) => obj.level === level);
  return result;
};

export const filterBundleLabelByFetch = (filteredBundle) =>
  _.chain(filteredBundle)
    .thru((bundle) => ({
      rating: _.chain(bundle).map("rating").uniq().sortBy().reverse().value(),
      level: _.chain(bundle).map("level").uniq().sortBy().value(),
      grade: _.chain(bundle).map("grade").uniq().sortBy().value(),
      subject: _.chain(bundle).map("subject").uniq().sortBy().value(),
    }))
    .value();

export const filterHarvest = ({ core, level }) => {
  const allLevel = _.map(core, (item) => ({
    id: item.id,
    name: item.name,
  }));

  const allGrade = _.get(
    _.find(core, (obj) => obj.id === level),
    "grades"
  );

  return {
    level: allLevel,
    grade: allGrade,
  };
};
