import React, { useState, useEffect } from "react";
import { Button, Input, Modal, Space } from "antd";
import {
  FacebookIcon,
  FacebookShareButton,
  EmailIcon,
  EmailShareButton,
  LineIcon,
  LineShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import styles from "./BundleShare.module.scss";
import CopyToClipboard from "react-copy-to-clipboard";
import { FaFacebook, FaLine, FaSquareXTwitter } from "react-icons/fa6";
import { SiGmail } from "react-icons/si";

const ShareModal = ({
  openShareBundle,
  closeShareModal,
  displayShareModal,
  url,
}) => {
  const [copy, setCopy] = useState(false);

  const handleClickCopyToClipboard = () => {
    setCopy(true);
  };

  return (
    <Modal
      open={displayShareModal}
      onCancel={() => {
        setCopy(false);
        closeShareModal();
      }}
      title={"แชร์"}
      footer={null}
    >
      <div className={styles.share_container}>
        <FacebookShareButton url={url}>
          <FaFacebook className={styles.icon} style={{ color: "#4267B2" }} />
        </FacebookShareButton>
        <LineShareButton url={url}>
          <FaLine className={styles.icon} style={{ color: "#5acf36" }} />
        </LineShareButton>
        <TwitterShareButton url={url}>
          <FaSquareXTwitter className={styles.icon} />
        </TwitterShareButton>
        <EmailShareButton url={url}>
          <SiGmail className={styles.icon} style={{ color: "#ea4335" }} />
        </EmailShareButton>
      </div>
      <Space.Compact
        direction="horizontal"
        className={styles.clipboard}
        style={{ width: "100%" }}
      >
        <Input value={url} disabled size="large" />
        <CopyToClipboard text={url} onCopy={() => handleClickCopyToClipboard()}>
          <Button>{copy ? "COPIED" : "COPY"}</Button>
        </CopyToClipboard>
      </Space.Compact>
    </Modal>
  );
};

export default ShareModal;
