import React, { useState } from "react";
import Logo from "assets/images/logo_kengsob.svg";
import { Drawer, Layout } from "antd";
import styles from "./Header.module.scss";
import GuestRightNav from "./RightNav/GuestRightNav";
import useWindowSize from "hook/useWindowSize";
import Hamberger from "assets/icons/button/Hamburger.svg";
import DrawerHeader from "./Drawer";

const { Header } = Layout;

const HeaderGuest = () => {
  const { width } = useWindowSize();

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Header className={styles.header_guest}>
      <div className={styles.header_guest_container}>
        {width > 600 ? (
          ""
        ) : (
          <div className={styles.hamberger} onClick={showDrawer}>
            <img
              alt="hamberger"
              src={Hamberger}
              className={styles.hamberger_icon}
            />
          </div>
        )}

        <div id="logo" href="/" className={styles.logo_container}>
          <img
            alt="logo"
            src={Logo}
            className={styles.logo}
          />
        </div>
        {width > 600 ? <GuestRightNav /> : ""}
      </div>
      <DrawerHeader onClose={onClose} open={open} isGuest={true} />
    </Header>
  );
};

export default HeaderGuest;
