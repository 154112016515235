import React, { useEffect, Fragment } from "react";
import { Alert } from "antd";
import styles from "./AlertBar.module.scss";
import { updateFirebaseToken } from "api";
import { throttleUpdateFirebaseToken } from "components/NavBar/throttleFirebase";

function AlertBar(props) {
  const textAlert =
    "กรุณาเปิดการแจ้งเตือน Notification เพื่อให้แอปทำงานได้ดีขึ้น";

  const requestPermission = () => {
    if ("Notification" in window) {
      window.Notification.requestPermission().then((permission) => {
        updateFirebaseToken("click:alert");
      });
    }
  };

  useEffect(() => {
    const updateTokenIfNeeded = async () => {
      const permission = window.Notification?.permission;
      if (permission) {
        localStorage.setItem("permission", permission);

        const blockedNoti =
          JSON.parse(localStorage.getItem("_fsbtk")) ===
          "messaging/permission-blocked";
        const closedNotification =
          blockedNoti || JSON.parse(localStorage.getItem("_fsbtk")) === "";
        const openPermission = permission === "granted";
        const blockPermission = permission === "denied";

        if (
          openPermission &&
          (!localStorage.getItem("_fsbtk") || closedNotification)
        ) {
          await throttleUpdateFirebaseToken();
        } else if (blockPermission && !blockedNoti) {
          await throttleUpdateFirebaseToken();
        }
      }
    };

    updateTokenIfNeeded();
  }, [props]);

  return (
    <Fragment>
      {window.Notification?.permission === "denied" &&
        window.location.origin !== "https://dev.kengsob.com" && (
          <div
            onClick={requestPermission}
            className={
              props?.pageInfo?.pageName === "Testing"
                ? styles.rootAlertBarTesting
                : styles.rootAlertBar
            }
          >
            <Alert message={textAlert} type="warning" />
          </div>
        )}
    </Fragment>
  );
}

export default AlertBar;
