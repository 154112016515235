import { LOCAL_STORAGE_KEYS } from 'util/config'

export const getLocalStorage = (key) => {
  // eslint-disable-next-line no-undef
  const value = localStorage.getItem(key)

  if (!value) return null

  try {
    return JSON.parse(value)
  } catch (error) {
    return null
  }
}

// eslint-disable-next-line no-undef
export const setLocalStorage = (key, value) => localStorage.setItem(key, JSON.stringify(value))

// eslint-disable-next-line no-undef
export const clearLocalStorage = (key) => localStorage.removeItem(key)

export const identifierLocalStorage = {
  set: ({ id, jwtToken, refreshToken, deviceId, language }) => setLocalStorage(LOCAL_STORAGE_KEYS.Identifier, { id, jwtToken, refreshToken, deviceId, language }),
  get: () => getLocalStorage(LOCAL_STORAGE_KEYS.Identifier),
  clear: () => clearLocalStorage(LOCAL_STORAGE_KEYS.Identifier)
}

export const testingLocalStorage = {
  set: (testingId, answers) => setLocalStorage(`${LOCAL_STORAGE_KEYS.TESTING}_${testingId}`, answers),
  get: (testingId) => getLocalStorage(`${LOCAL_STORAGE_KEYS.TESTING}_${testingId}`),
  clear: (testingId) => clearLocalStorage(`${LOCAL_STORAGE_KEYS.TESTING}_${testingId}`)
}

export const otpLocalStorage = {
  set: ({ otpCode, verifyToken }) => setLocalStorage(LOCAL_STORAGE_KEYS.OTP, { otpCode, verifyToken, timestamp: new Date().getTime() }),
  get: () => getLocalStorage(LOCAL_STORAGE_KEYS.OTP),
  clear: () => clearLocalStorage(LOCAL_STORAGE_KEYS.OTP)
}

export const PretestLS = {
  set: (pretestId, data) => setLocalStorage(pretestId, data),
  get: (pretestId) => getLocalStorage(pretestId),
  exist: (pretestId) => getLocalStorage(pretestId) !== null,
  clear: (pretestId) => clearLocalStorage(pretestId)
}

export const firebaseLS = {
  set: (token) => setLocalStorage(LOCAL_STORAGE_KEYS.FirebaseToken, token),
  get: () => getLocalStorage(LOCAL_STORAGE_KEYS.FirebaseToken),
  clear: () => clearLocalStorage(LOCAL_STORAGE_KEYS.FirebaseToken)
}

export const clearAllLocal = () => {
  const existKeysLocal = [
    'firebase:host:sobtid-kiddy.firebaseio.com',
    '__fb_chat_plugin',
    'firebase:host:sobtid-dev.firebaseio.com',
  ]
  if (Object.keys(localStorage).length !== 0) {
    for (let keys in localStorage) {
      if (existKeysLocal.includes(keys)) {
        continue
      } else {
        localStorage.removeItem(keys)
      }
    }
  }
}

export const DEFAULT_TARGET_LANGUAGE = (getLocalStorage('_lang') ? getLocalStorage('_lang') : 'th')