import React from 'react'
import { Button } from 'antd'

const stylesNoText = {
  width : 48,
  height : 48,
  padding : '10px 12px 12px 12px'
}
const stylesMini = {
  minWidth : '80',
  MinHeight : '28',
  padding : '10px 12px 12px 12px'
}
const stylesNoTextSecondary = {
  width : 48,
  height : 48,
  padding : '11px 12px 12px 12px'
}
const styles = {
  minWidth : 160,
  MinHeight : 48
}

export const ButtonPrimary = ({text, icon , onClick , size }) => {
  return(
    <Button type='primary' onClick = {onClick} style = {text ? size === 'mini' ? stylesMini : styles  : stylesNoText}>
      {
        icon ? <img alt = {text ? text : ''} src ={icon ? icon : null } style = {{marginRight : 8}}/>: ''
      }
      {text ? text : ''}
    </Button>
  )
}
  
 
export const ButtonSecondDary = ({text, icon , onClick }) => (
  <Button onClick = {onClick} style = {text ? styles : stylesNoTextSecondary}>
    {
      icon ? <img alt = {text ? text : ''} src ={icon ? icon : null } style = {{marginRight : 8}}/>: ''
    }
    {text ? text : ''}
  </Button>
)
export const ButtonTextIcon = ({type, text, icon, disabled, onClick, styled}) => (
  <Button type = {type} disabled={disabled} onClick = {onClick} style = {styled}>
    {icon && <img alt = {text ? text : ''} src ={icon ? icon : null } />}
    {text ? text : 'Button'}
  </Button>
)