import React from "react";
import styles from "../Header.module.scss";

import { Dropdown, Avatar, Tag } from "antd";
import IconProfile from "assets/icons/icon-profile-cu.png";
import iconLogOut from "assets/icons/icon-logout-cu.png";
import IconDropdownBlack from "assets/icons/icon-dropdown-black.svg";
import MenuDropdown from "components/Menu/MenuDropdown";
import KidProfile from "assets/images/profile/profile-picture-04.svg";

import { USER_ROLE } from "util/config";
import ListDropdown from "components/List/ListDropdown";
import { TranslateLanguage } from "util/Translate";
import { vocabSchoolCollabUserRightNav } from "util/Translate.variable";
import useWindowSize from "hook/useWindowSize";

const translate = new TranslateLanguage();
translate.addMaping(vocabSchoolCollabUserRightNav);

export default function SchoolCollabUserRightNav(props) {
  const { user } = props;
  const { width } = useWindowSize();

  return (
    <div className={styles.subProfile}>
      <div
        className={styles.nameAndSchool}
        onClick={() => {
          props.props.history.push("/profile");
        }}
      >
        <div className={styles.divDisplayName}>
          {user.displayName || "Guest"}
        </div>
        <div className={styles.divGrade}>
          {user.role === USER_ROLE.TEACHER
            ? `${translate.convert("โรงเรียน")}${user.schoolCollabName}`
            : `ID: ${user.studentId}`}
        </div>
      </div>

      <div className={styles.display}>
        <div
          className={styles.profile}
          onClick={() => {
            props.props.history.push("/profile");
          }}
        >
          <Avatar
            src={user.imageProfile || KidProfile}
            className={styles.avatar}
          />
        </div>
          <div
            className={styles.tagStyle}
            onClick={() => {
              props.props.history.push("/profile");
            }}
          >
            <Tag color="#EE5294">School</Tag>
          </div>

        <div className={styles.divDropdown}>
          <Dropdown
            overlay={MenuDropdown([
              {
                label: (
                  <ListDropdown
                    text={translate.convert("ข้อมูลผู้ใช้งาน")}
                    icon={IconProfile}
                    action={props.onProfile}
                  />
                ),
              },
              {
                label: (
                  <ListDropdown
                    text={translate.convert("ออกจากระบบ")}
                    icon={iconLogOut}
                    action={props.onSignOut}
                  />
                ),
              },
            ])}
            trigger={["click"]}
            placement="bottomRight"
            arrow
          >
            <div style={{ cursor: "pointer" }}>
              <Avatar alt="dropdownprofile" src={IconDropdownBlack} />
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
