import dayjs from 'dayjs'
import "dayjs/locale/th";
import buddhistEra from "dayjs/plugin/buddhistEra";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration)
dayjs.extend(buddhistEra);

export const FullDateTimeTH = (ts) => {
    dayjs.locale("th");
    return dayjs(ts).format("DD MMMM BBBB, HH:mm น.");
}

export const toDurationTime = (ts) => {
    return dayjs.duration(ts).format("HH:mm:ss")
}

export const toBuddhistYear = (moment, format) => {
    const christianYear = moment.format("YYYY");
    const buddhistYear = (parseInt(christianYear) + 543).toString();
    const formattedDate = moment
        .format(
            format
                .replace("YYYY", buddhistYear)
                .replace("YY", buddhistYear.substring(2, 4))
        )
        .replace(christianYear, buddhistYear);

    return formattedDate;
};
