import React from "react";
import { ButtonTextIcon } from "components/Button/Button";
import IconProfileDefault from "assets/icons/icon-profile-selected.svg";
import IconLogin from "assets/icons/icon-signin.svg";
import { TranslateLanguage } from "util/Translate";
import { vocabGuestRightNav } from "util/Translate.variable";
import styles from "../Header.module.scss";

const translate = new TranslateLanguage();
translate.addMaping(vocabGuestRightNav);

export default function GuestRightNav() {
  return (
    <div className={styles.right_nav}>
      <a id="login" href="/login" className={styles.button}>
        <ButtonTextIcon
          type="text"
          text={translate.convert("เข้าสู่ระบบ")}
          icon={IconProfileDefault}
          styled={{fontSize: "0.85rem"}}
        />
      </a>
      <a id="register" href="/register" className={styles.button}>
        <ButtonTextIcon
          type="text"
          text={translate.convert("สมัครสมาชิก")}
          icon={IconLogin}
          styled={{fontSize: "0.85rem"}}
        />
      </a>
    </div>
  );
}
