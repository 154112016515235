/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect } from "react";
import AppRouter from "./router";
import { getProfileService } from "./api/auth";
import { actionType } from "./reducers/auth";
import useAuth, { AuthProvider } from "./context/auth";
import ReactGA from "react-ga";
import { message } from "antd";
import { ConfigProvider } from "antd";
import * as Sentry from "@sentry/react";
import * as SentryBrowser from "@sentry/browser";
import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  PRIMARY_700_CU_COLOR,
  PRIMARY_800_CU_COLOR,
} from "util/config";
import { DataStoreProvider } from "store/DataStoreContext";

Sentry.init({
  dsn: "https://3eca075bd3c5134b2dfbb7001d3c4d3c@o4505549569851392.ingest.sentry.io/4506150176620544",
  integrations: [new SentryBrowser.BrowserTracing()],
  environment: "production",
});

function App() {
  const {
    state: { user, isAuthenticated },
    dispatch,
  } = useAuth();

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      process.env.REACT_APP_ENV === "production"
        ? "https://t.2c2p.com/SecurePayment/api/my2c2p.1.7.3.min.js"
        : "https://demo2.2c2p.com/2C2PFrontEnd/SecurePayment/api/my2c2p.1.6.9.min.js";
    document.body.appendChild(script);
    script.type = "text/javascript";
    script.async = true;
  }, []);

  useEffect(() => {
    ReactGA.initialize(`${process.env.REACT_APP_GA_ID}`, {
      titleCase: false,
      gaOptions: {
        userId: user?.id,
      },
    });
    // eslint-disable-next-line no-undef
    ReactGA.pageview(window.location.pathname + window.location.search);
    let ignore = false;

    // eslint-disable-next-line no-undef
    const screenLoader = document.getElementById("screenLoader");

    if (screenLoader && !screenLoader.hasAttribute("hidden")) {
      setTimeout(function () {
        screenLoader.setAttribute("hidden", "true");
      }, 1000);
    }

    async function fetchUserProfile() {
      try {
        let payload = await getProfileService();
        if (!ignore) {
          dispatch({ type: actionType.LOAD_PROFILE, user: payload });
          try {
            if (payload) {
              Sentry.setUser({
                user: JSON.stringify(
                  {
                    id: payload?.id,
                    email: payload?.email,
                    displayName: payload?.displayName,
                    grade: payload?.grade,
                    studentId: payload?.studentId,
                    schoolCollabName: payload?.schoolCollabName,
                  },
                  null,
                  2
                ),
              });
            }
          } catch (error) {}
        }
      } catch (error) {
        message.error("เกิดข้อผิดพลาดในการเรียกข้อมูล");
      }
    }
    if (!user && isAuthenticated) {
      fetchUserProfile();
    }
    return () => {
      ignore = true;
    };
  }, [dispatch, isAuthenticated, user]);

  if (!user && isAuthenticated) {
    return null;
  }

  return <AppRouter />;
}

export default () => (
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: PRIMARY_700_CU_COLOR,
        colorInfoTextHove: SECONDARY_COLOR,
        colorPrimaryHover: PRIMARY_800_CU_COLOR,
        colorPrimaryTextHover: SECONDARY_COLOR,
        colorInfoTextHover: SECONDARY_COLOR,
        blue5: SECONDARY_COLOR,
        "blue-5": SECONDARY_COLOR,
      },
    }}
  >
    <AuthProvider>
      <Sentry.ErrorBoundary fallback={"Something went wrong!"}>
        <DataStoreProvider>
          <App />
        </DataStoreProvider>
      </Sentry.ErrorBoundary>
    </AuthProvider>
  </ConfigProvider>
);
