/* eslint-disable default-case */
import axios from "axios";
import { message } from "antd";
import { deviceDetect } from "react-device-detect";
import { idGenerate } from "util/helper";
import {
  identifierLocalStorage,
  DEFAULT_TARGET_LANGUAGE,
} from "util/localstorage";
import * as qs from "qs";

const controller = new AbortController();

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}` || "/api",
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: "brackets" });
  },
  headers: {
    "accept-language": "th",
  },
});
instance.defaults.signal = controller.signal;

instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    switch (error?.response?.status) {
      case 403:
        break;
      case 502:
        message.error("เกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่ผู้ดูแลระบบ");
        break;
      case 500:
        message.error("เกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่ผู้ดูแลระบบ");
        break;
      case 401:
        // eslint-disable-next-line no-case-declarations
        const identifier = identifierLocalStorage.get();
        if (identifier !== null && !error.config.__isRetryRequest) {
          error.config._retry = true;
          const bearer = `Bearer ${identifier?.jwtToken}`;
          // eslint-disable-next-line no-undef
          return fetch(`${process.env.REACT_APP_API_URL}/auth/token`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
              "Content-Type": "application/json",
              Authorization: bearer,
            },
            redirect: "follow",
            referrer: "no-referrer",
            body: JSON.stringify({
              id: identifier.id,
              refreshToken: identifier.refreshToken,
              deviceId: identifier.deviceId,
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.error) {
                identifierLocalStorage.clear();
                return (window.location.href = "/login");
              }
              const idRef = deviceDetect();
              const deviceType = idRef
                ? idRef.browserName + ", " + idRef.osName
                : "";
              identifier.deviceId = idGenerate(deviceType);
              identifier.deviceType = deviceType;
              identifier.jwtToken = data.jwtToken;
              identifier.refreshToken = data.refreshToken;
              identifierLocalStorage.set(identifier);
              setAuthorizationToken(data.jwtToken);
              error.config.headers.Authorization = `Bearer ${data.jwtToken}`;
              return instance(error.config);
            })
            .catch((e) => {
              identifierLocalStorage.clear();
              return (window.location.href = "/login");
            });
        }
    }
    return Promise.reject(error.response);
  }
);
export const handleRequest = async (fetching) => {
  try {
    const response = await fetching;
    return response;
  } catch (e) {
    return {
      error: getErrorMessage(e),
    };
  }
};

export const setAuthorizationToken = (token) => {
  if (token) {
    instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete instance.defaults.headers.common["Authorization"];
  }
};

export const setLanguageAPI = (language) => {
  if (language) {
    instance.defaults.headers.common["Accept-Language"] = language;
  }
};

const getErrorMessage = (e) => {
  if (caseErrorAmountDevice422(e)?.checkStartTesting) {
    return caseErrorAmountDevice422(e).res;
  } else if (caseErrorAmountDevice422(e)?.checkFinishTesting) {
    message.error(e.data.message, [10]);
    return caseErrorAmountDevice422(e).res;
  } else if (caseErrorAccess403(e)?.checkAccess) {
    message.error(caseErrorAccess403(e).res.message, [10]);
    return caseErrorAccess403(e).res;
  }
  return {
    code: e?.status || null,
    message: e?.data ? e?.data?.message : "",
    messageCode: e?.data ? e?.data?.statusCode : "",
  };
};

const caseErrorAmountDevice422 = (e) => {
  return {
    checkStartTesting:
      e?.data?.statusCode === "amount_device_exceeded" ||
      e?.data?.statusCode === "lessons_not_have_question",
    checkFinishTesting: e?.data?.statusCode === "block_device_testing",
    res: {
      code: e?.status,
      message: e?.data ? e?.data?.message : "",
      errorTesting: e?.data,
    },
  };
};

// use case = เข้าผ่านรายละเอียด pretest หรือไม่ก็ผ่าน start pretest testing
const caseErrorAccess403 = (e) => {
  try {
    if (e) {
      return {
        checkAccess: e.data.statusCode === "block_access_pretest",
        res: {
          code: e?.status,
          message: e?.data ? e?.data?.message : "",
          errorTesting: e?.data,
        },
      };
    }
  } catch (error) {
    message.error("เกิดข้อผิดพลาดในการเข้าถึงข้อมูล");
  }
};

export default instance;
