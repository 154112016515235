import React, { useState, useEffect } from "react";
import styles from "./myFavorite.module.scss";
import useWindowSize from "hook/useWindowSize";
import TitleAndBreadCrumb from "components/TitleAndBreadCrumb/TitleAndBreadCrumb";
import { TranslateLanguage } from "util/Translate";
import { useDataStore } from "store/DataStoreContext";
import { actionType } from "store/dataReducer";
import { getMyFavoriteBundle } from "api/bundle";
import { Empty, Spin } from "antd";
import _ from "lodash";
import Card from "pages/Home/Components/BundleCard/Card";
import TableBundle from "pages/Home/Components/BundleCard/TableBundle";
import useComponentDimension from "util/useComponentDimension";
import ImgNoResult from "assets/images/no-result.svg";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { getMyFavoritePretestService } from "api/pretest";
import TablePretest from "components/Card/Pretest/TablePretest";
import CardPretest from "components/Card/Pretest/CardPretest";
import ReactHowler from "react-howler";

const translate = new TranslateLanguage();

const MyFavorite = (props) => {
  const [ref, dimensions] = useComponentDimension();
  const { width } = useWindowSize();
  const {
    state: { bundle, myFavorite, displayType },
    dispatch,
  } = useDataStore();

  const [typeTab, setTypeTab] = useState("bundle");
  const [loading, setLoading] = useState(false);

  const [cardWidth, setCardWidth] = useState({
    noFilter: {
      gap: undefined,
    },
    withFilter: {
      gap: undefined,
    },
  });

  useEffect(() => {
    initMyExamination();
  }, [typeTab]);

  useEffect(() => {
    calCardWidth();
  }, [dimensions]);

  const initMyExamination = async () => {
    setLoading(true);
    if (typeTab === "bundle") {
      await getMyFavoriteBundle().then((res) => {
        dispatch({
          type: actionType.FETCH_MY_FAVORITE,
          payload: { bundle: res },
        });
      });
    } else {
      await getMyFavoritePretestService().then((res) => {
        dispatch({
          type: actionType.FETCH_MY_FAVORITE,
          payload: { pretest: res },
        });
      });
    }
    setLoading(false);
  };

  const calCardWidth = () => {
    const componentWidth = dimensions.width;
    const componentWithFilter = componentWidth * 0.65;
    const cardCount = Math.floor(componentWidth / 170);
    const leftWidth = componentWidth - cardCount * 170 - 24;

    const gapWidth = leftWidth / cardCount;

    setCardWidth({
      noFilter: {
        gap: gapWidth,
      },
      withFilter: {
        gap: undefined,
        componentWidth: componentWithFilter,
      },
    });
  };

  const handleChangeMode = (value) => {
    dispatch({ type: actionType.CHANGE_DISPLAY_TYPE, payload: value });
  };

  const handleClickCard = (id) => {
    props.history.push(`/bundle/detail`, { bundleId: id });
  };

  const onClickCard = (pretest, e) => {
    if (
      e?.target.tagName.toLowerCase() === "span" ||
      e?.target.tagName.toLowerCase() === "button"
    ) {
      return;
    }
    if (pretest.id !== "a6f2678a-df43-4d30-98e5-a9dbcb36ec77") {
      return props.history.push(`/pretest/${pretest.id}`);
    }
  };

  return (
    <div className={styles.main} style={width > 1100 ? {} : { margin: "15px" }}>
      <TitleAndBreadCrumb
        path={{
          history: [
            {
              textGoBack: translate.convert("หน้าแรก"),
              text: translate.convert("รายการที่ชอบ"),
              goBack: () => props.history.push("/"),
            },
          ],
        }}
        title={{ text: translate.convert("รายการที่ชอบ") }}
        option={{
          options: {
            mode: {
              value: bundle.displayType,
              onChange: handleChangeMode,
            },
          },
        }}
      />
      <div className={styles.bundle_container_level} ref={ref}></div>
      <div className={styles.button_con}>
        <Tabs>
          <TabList>
            <Tab
              onClick={() => setTypeTab("bundle")}
              style={typeTab === "bundle" ? { color: "#ee5294" } : {}}
            >
              Bundle
            </Tab>
            <Tab
              onClick={() => setTypeTab("pretest")}
              style={typeTab === "pretest" ? { color: "#ee5294" } : {}}
            >
              Pretest
            </Tab>
          </TabList>
        </Tabs>
      </div>
      {typeTab === "bundle" ? (
        <div className={styles.container}>
          {!loading ? (
            _.size(myFavorite.bundle.data) > 0 ? (
              myFavorite.bundle.data.map((item, id) =>
                displayType === "card" ? (
                  <Card
                    bundleItem={item}
                    key={id}
                    handleClickCard={handleClickCard}
                    refetch={initMyExamination}
                  />
                ) : (
                  <TableBundle
                    bundleItem={item}
                    key={id}
                    handleClickCard={handleClickCard}
                    refetch={initMyExamination}
                  />
                )
              )
            ) : (
              <div style={{ textAlign: "center", margin: "20% auto" }}>
                <img src={ImgNoResult} alt="ImgNoResult" />
                <div
                  clazssName={styles.text_no_result}
                  style={{ color: "#999899" }}
                >
                  ไม่พบข้อมูล
                </div>
              </div>
            )
          ) : (
            <Spin size="large" style={{ margin: "20% auto" }} />
          )}
        </div>
      ) : !loading ? (
        <div
          className={styles.pretest_container}
          style={
            displayType === "card" && _.size(myFavorite.pretest.data) > 0
              ? {
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: "2rem",
                  justifyItems: "stretch",
                }
              : {}
          }
        >
          {_.size(myFavorite.pretest.data) > 0 ? (
            myFavorite.pretest.data.map((item, id) =>
              displayType === "card" ? (
                <CardPretest
                  history={props.history}
                  key={id}
                  pretest={item}
                  // fetchListPretests={fetchListPretests}
                  onClickCard={() => onClickCard(item)}
                />
              ) : (
                <TablePretest
                  key={id}
                  history={props.history}
                  pretest={item}
                  // fetchListPretests={fetchListPretests}
                  onClickCard={() => onClickCard(item)}
                />
              )
            )
          ) : (
            <div style={{ textAlign: "center", margin: "20% auto" }}>
              <img src={ImgNoResult} alt="ImgNoResult" />
              <div
                clazssName={styles.text_no_result}
                style={{ color: "#999899" }}
              >
                ไม่พบข้อมูล
              </div>
            </div>
          )}
        </div>
      ) : (
        <Spin
          size="large"
          style={{
            display: "flex",
            margin: "20% auto",
            justifyContent: "center",
          }}
        />
      )}
    </div>
  );
};

export default MyFavorite;
