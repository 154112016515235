import React, { useState, useEffect } from "react";
import { Button, Layout, message, Input, Dropdown, Space } from "antd";
import styles from "./Header.module.scss";
import Logo from "assets/images/logo_kengsob.svg";

import { actionType } from "reducers/auth";

import { logoutService } from "api/auth";
import useAuth from "context/auth";
import UserRightNav from "./RightNav/UserRightNav";
import SchoolCollabUserRightNav from "./RightNav/SchoolCollabUserRightNav";
// import Search from "../Search/Search";
import ReactGA from "react-ga";
import { TranslateLanguage } from "util/Translate";
import { vocabHeaderCMS } from "util/Translate.variable";
import { UnorderedListOutlined } from "@ant-design/icons";
import useWindowSize from "hook/useWindowSize";
import DrawerHeader from "./Drawer";
import Hamberger from "assets/icons/button/Hamburger.svg";
import { RxHamburgerMenu } from "react-icons/rx";
import _ from "lodash";
import {
  FolderOutlined,
  HeartOutlined,
  AudioOutlined,
  DownOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useDataStore } from "store/DataStoreContext";
import { actionType as storeActionType } from "store/dataReducer";

const translate = new TranslateLanguage();
translate.addMaping(vocabHeaderCMS);
const { Search } = Input;
const { Header } = Layout;

const HeaderCMS = React.memo((props) => {
  const { width, height } = useWindowSize();
  const [searchType, setSearchType] = useState("bundle");

  const { user, roleMenu } = props;
  const { dispatch } = useAuth();
  const {
    state: { search },
    dispatch: storeDispatch,
    fetchBundleBySearch,
    fetchBundleByFilter,
    fetchPretestBySearch,
  } = useDataStore();

  const path = props.location.pathname;
  const goToProfile = () => {
    props.history.push("/profile");
  };
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);

  const handleSignOut = async () => {
    const res = await logoutService();
    if (res.error) return message.error(`ไม่สามารถออกจากระบบได้ ${res.error}`);
    dispatch({ type: actionType.LOGOUT });
    props.history.push("/login");
    window.location.reload();
    ReactGA.event({
      category: "User",
      action: "logout",
      label: "logout",
    });
  };

  const items = [
    {
      label: "ชุดข้อสอบ",
      key: "bundle",
    },
    {
      label: "Pretest",
      key: "pretest",
    },
  ];

  const handleMenuClick = (e) => {
    setSearchType(e.key);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const suffix = (
    <Dropdown menu={menuProps} trigger={["click"]} className={styles.menu}>
      <Space>
        {searchType === "bundle" ? "ชุดข้อสอบ" : "Pretest"}
        <DownOutlined />
      </Space>
    </Dropdown>
  );

  useEffect(() => {
    if (props.location.pathname !== "/group/search") {
      setSearchValue("");
    }
  }, [props.location.pathname]);

  const isUserSchoolCollab = () => user.schoolCollabName !== undefined;

  const handleSearchGroup = (e) => {
    const { value } = e.target;
    const check = value.split("qrCode=");
    if (check.length === 2) {
      setSearchValue("");
      return props.history.push(`/group/search?qrCode=${check[1]}`);
    }
    if (value) {
      props.history.push(`/group/search?query=${value}`);
    } else {
      props.history.push("/group");
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleChangeSearch = (e) => {
    storeDispatch({
      type: storeActionType.CHANGE_SEARCH,
      payload: e.target.value,
    });
  };

  const handleSearch = (value) => {
    if (value !== "") {
      if (searchType === "bundle") {
        if (props.history.location.pathname !== "/bundle") {
          props.history.push("/bundle");
        } else {
          fetchBundleBySearch(value);
        }
      } else if (searchType === "pretest") {
        if (props.history.location.pathname !== "/pretest") {
          props.history.push("/pretest");
        } else {
          fetchPretestBySearch(value);
        }
      }
    } else {
      if (searchType === "bundle") {
        fetchBundleByFilter();
      } else {
        fetchPretestBySearch();
      }
    }
  };

  return (
    <Header className={styles.header_user}>
      {width < 800 && (
        <div className={styles.user_hamberger}>
          <RxHamburgerMenu className={styles.hamberger} onClick={showDrawer} />
        </div>
      )}
      <div className={styles.nav}>
        <div
          className={styles.top_nav}
          style={
            width > 800
              ? {
                  alignItems: "center",
                  justifyContent: "space-between",
                }
              : { justifyContent: "center" }
          }
        >
          <div className={styles.left_header}>
            <div className={styles.divLogo}>
              <a href="/">
                <img alt="logo" src={Logo} className={styles.logo} />
              </a>
            </div>
            {width > 800 && (
              <div className={styles.divMenuItem}>
                {_.size(roleMenu) > 1 &&
                  roleMenu.map((menu, key) => {
                    return (
                      <div key={key} className={styles.divMenuItemLink}>
                        <div
                          className={
                            menu.path ===
                            (props.location.pathname === "/"
                              ? "/home"
                              : props.location.pathname)
                              ? styles.btnMenuSelected
                              : styles.btnMenu
                          }
                          onClick={async () => {
                            props.history.push(menu.path);
                          }}
                        >
                          {menu.text}
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          <div className={styles.right}>
            {width > 800 && (
              <UserRightNav
                user={user}
                onSignOut={handleSignOut}
                onProfile={goToProfile}
                props={props}
              />
            )}
          </div>
        </div>
        {width > 800 && (
          <div className={styles.bottom_nav}>
            <div className={styles.left}>
              <Search
                enterButton
                suffix={suffix}
                className={styles.search}
                onChange={handleChangeSearch}
                onSearch={handleSearch}
                allowClear={true}
                value={search}
              />
            </div>
            <div className={styles.right}>
              <div
                className={styles.button}
                style={
                  props.location.pathname === "/myExam"
                    ? { marginRight: "1rem", color: "#ee5294" }
                    : { marginRight: "1rem" }
                }
                onClick={() => {
                  props.history.push("/myExam");
                }}
              >
                <FolderOutlined
                  style={{ marginRight: ".5rem" }}
                  onClick={() => {
                    props.history.push("/myExam");
                  }}
                />
                ข้อสอบของฉัน
              </div>
              <div
                className={styles.button}
                style={
                  props.location.pathname === "/myFavorite"
                    ? { color: "#ee5294" }
                    : {}
                }
                onClick={() => {
                  props.history.push("/myFavorite");
                }}
              >
                <HeartOutlined style={{ marginRight: ".5rem" }} />
                รายการที่ชอบ
              </div>
            </div>
          </div>
        )}
      </div>

      {width < 800 && (
        <DrawerHeader
          props={props}
          onClose={onClose}
          open={open}
          isGuest={false}
          handleSignOut={handleSignOut}
          goToProfile={goToProfile}
        />
      )}
    </Header>
  );
});

export default HeaderCMS;
