import LayoutExternal from 'Layout/Layout/LayoutExternal'
import React from 'react'
import styles from './ErrorBoundary.module.scss'
import maintenance from 'assets/images/layout/maintenance.png'
import kiddylogo from 'assets/images/layout/kiddy-logo.png'
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // // You can also log the error to an error reporting service 
    // console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <LayoutExternal>
          <div className={styles.divError}>
            <img alt='maintenance' src={maintenance} />
            <h2>ระบบปิดปรับปรุงชั่วคราว</h2>
            <p>กรุณาแจ้งปัญหาที่เกิดขึ้น ผ่านทาง line @sobtid</p>
            <p>หลังจากนั้นทำการ Refresh หน้านี้อีกครั้ง</p>
            <img className={styles.logo} alt='kiddy-logo' src={kiddylogo} />
          </div>
        </LayoutExternal>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
