import { updateFirebaseToken } from "api";

let isUpdatingToken = false;

export const throttleUpdateFirebaseToken = async (action) => {
  if (isUpdatingToken) {
    return;
  }
  isUpdatingToken = true;

  await updateFirebaseToken(action);

  isUpdatingToken = false;
};
