import { Divider, Drawer, Layout, Button } from "antd";
import styles from "./Drawer.module.scss";
import Close from "assets/icons/close_icon.svg";
import IconProfileDefault from "assets/icons/icon-profile-selected.svg";
import IconLogin from "assets/icons/icon-signin.svg";
import { useHistory } from "react-router-dom";
import useAuth from "context/auth";
import UserDeafault from "assets/icons/profile/Default.svg";
import { userMenuSchema } from "Layout/Layout/LayoutCMS";
import { useState, useEffect } from "react";
import { USER_ROLE } from "util/config";
import Profile from "assets/icons/profile/Profile.svg";
import SignOut from "assets/icons/profile/Sign_out.svg";
import _ from "lodash";
import { GRADES } from "util/config";
import { FolderOutlined, HeartOutlined } from "@ant-design/icons";

const DrawerHeader = ({
  open,
  onClose,
  isGuest,
  handleSignOut,
  goToProfile,
  props,
}) => {
  const history = useHistory();
  const {
    state: { user, isAuthenticated },
  } = useAuth();

  const [roleMenu, setRoleMenu] = useState(userMenuSchema.guest);
  const [activePage, setActivePage] = useState("");

  useEffect(() => {
    if (isAuthenticated) {
      if (user.role === USER_ROLE.TEACHER) {
        setRoleMenu(userMenuSchema.schoolCollabTeacher);
      } else if (user.role === USER_ROLE.STUDENT && user.schoolCollabName) {
        setRoleMenu(userMenuSchema.schoolCollabStudent);
      } else if (user.role === USER_ROLE.STUDENT && !user.schoolCollabName) {
        setRoleMenu(userMenuSchema.normalStudent);
      }
    } else {
      setRoleMenu(userMenuSchema.guest);
    }
  }, []);

  useEffect(() => {
    setActivePage(history.location.pathname.split("/")[1]);
  }, [open]);

  const handleRouteLogin = () => {
    history.push("/login");
    onClose();
  };

  const handleRouteRegister = () => {
    history.push("/register");
    onClose();
  };

  const handleGoToProfile = () => {
    goToProfile();
    onClose();
  };

  const handleGoToMyExam = () => {
    history.push("/myExam");
    onClose();
  };

  const handleGoToMyFavorite = () => {
    history.push("/myFavorite");
    onClose();
  };

  const handleRoute = (item) => {
    history.push(item.path);
    onClose();
  };

  return (
    <Drawer placement={"left"} closable={false} onClose={onClose} open={open}>
      <div className={styles.container}>
        <div className={styles.close_logo}>
          <img src={Close} alt="close" onClick={onClose} />
        </div>
        {isGuest ? (
          <div className={styles.drawer_guest}>
            <div onClick={handleRouteLogin} className={styles.button_con}>
              <img
                src={IconProfileDefault}
                alt="icon"
                className={styles.icon}
              />
              <div className={styles.button_text_text}>เข้าสู่ระบบ</div>
            </div>
            <div onClick={handleRouteRegister} className={styles.button_con}>
              <img src={IconLogin} alt="icon" />
              <div className={styles.button_text_text}>สมัครสมาชิก</div>
            </div>
          </div>
        ) : (
          <div className={styles.user_container}>
            <div className={styles.use_container}>
              <div className={styles.user_display}>
                <img
                  src={user.imageProfile ? user.imageProfile : UserDeafault}
                  alt="user"
                  className={styles.user_image}
                />
              </div>
              <div className={styles.user_info}>
                <div className={styles.user_name}>{user.displayName}</div>
                <div className={styles.user_sub_info}>
                  <div className={styles.user_grade}>
                    {user.studentId
                      ? user.studentId
                      : _.find(GRADES, {
                          key: user.grade,
                        }).text}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.navigate_container}>
              <Divider />
              {roleMenu.map((menu, key) => {
                return (
                  <div
                    key={key}
                    className={
                      menu.path.substring(1) === activePage
                        ? styles.navigate_item_active
                        : styles.navigate_item_non_active
                    }
                    onClick={() => handleRoute(menu)}
                  >
                    <div className={styles.icon}>
                      <img
                        src={menu.getIcon(
                          menu.path.substring(1) === activePage
                        )}
                        alt="icon"
                      />
                    </div>
                    <div className={styles.navigate_text}>{menu.text}</div>
                  </div>
                );
              })}
            </div>
            <div className={styles.action_container}>
              <Divider />
              <div className={styles.user_detail} onClick={handleGoToMyExam}>
                <FolderOutlined style={{ marginRight: "1rem" }} />
                ข้อสอบของฉัน
              </div>
              <div
                className={styles.user_detail}
                onClick={handleGoToMyFavorite}
              >
                <HeartOutlined style={{ marginRight: "1rem" }} />
                รายการที่ชอบ
              </div>
              <div className={styles.user_detail} onClick={handleGoToProfile}>
                <img src={Profile} alt="icon" />
                <div className={styles.detail_text}>ข้อมูลผู้ใช้งาน</div>
              </div>
              <div className={styles.user_detail} onClick={handleSignOut}>
                <img src={SignOut} alt="icon" />
                <div className={styles.detail_text}>ออกจากระบบ</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default DrawerHeader;
