import React from 'react'
import { Modal, Button } from 'antd'
import styles from './ModalWrong.module.scss'
import IconWrong from 'assets/icons/icon-not-true.svg'
import { TranslateLanguage } from 'util/Translate'
import { vocabModalWrong } from 'util/Translate.variable'

const translate = new TranslateLanguage()
translate.addMaping(vocabModalWrong)

const ModalWrong = (props) => {
  const {
    visible,
    setVisible,
    onOk = null,
    title = translate.convert('ยกเลิกแพ็กเกจสำเร็จ'),
    setBody = translate.convert('ระบบได้ส่งอีเมลแจ้งเตือนไปยังอีเมลของคุณ \n กรุณาตรวจสอบอีเมล') } = props
  const onClose = () => setVisible(false)
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={false}
      bodyStyle={{ padding: '32px 64px', width: '540px', height: '431px' }}
      centered
    >
      <div className={styles.modal_wrong}>
        <img alt='IconWrong' src={IconWrong} />
        <h4>{title}</h4>
        <span>{setBody && setBody.includes('\n') ? setBody.split('\n').map((value) => <p> {value} </p>) : setBody} </span><br />
        <Button onClick={onOk ? onOk : onClose} type='primary'>{translate.convert('ตกลง')}</Button>
      </div>
    </Modal>
  )
}
export default ModalWrong