import axios, { handleRequest } from "./kiddy-connector";
import qs from "qs";
import _ from "lodash";

const GetNewGradeAPI = () =>
  axios.get("master/newGrade").then((response) => response.list);
const GetSubjectAPI = (params) =>
  axios.get("master/subjects", { params }).then((response) => response.list);
const getPackagesAPI = () =>
  axios.get("master/packages").then((response) => response.list);
const getExpandSubjectAPI = (grade, level) =>
  axios
    .get(`master/expandSubjects?grade=${grade}&level=${level}`)
    .then((response) => response.list);
const GetExpandCategoriesAPI = (params) =>
  axios
    .get("master/expandCategories", { params })
    .then((response) => response.list);
const GetGradeAPI = (params) =>
  axios.get(`master/grade?subjectId=${params ? params : ""}`).then((response) =>
    response.list.map((grade) => ({
      ...grade,
      name: grade.webText,
      key: grade.grade,
    }))
  );
const getTabGradeAPI = (publisherId, subjectId, level) =>
  axios
    .get(
      `master/tabGrade?publisherId=${publisherId}&subjectId=${subjectId}&level=${level}`,
      {
        paramsSerializer: (params) => qs.stringify(params, { encode: false }),
      }
    )
    .then((response) =>
      response.list.map((grade) => ({
        ...grade,
        name: grade.webText,
        key: grade.grade,
      }))
    );
const GetSubjectTab = (grade, publisherId, level, showAll) =>
  axios
    .get(
      `master/subjects?grade=${grade}&showAll=${showAll}&level=${level}&publisherId=${publisherId}`
    )
    .then((response) =>
      response.list.map((subject) => ({
        ...subject,
        name: subject.name,
        key: subject.id ? subject.id : "all",
      }))
    );
const getTermAPI = () => axios.get("master/termWeb");
const getPolicyAPI = () => axios.get("master/policy");
const GetNewsAPI = () => axios.get("master/news");
const GetEducationType = (publisherId) =>
  axios
    .get(`master/educationType?publisherId=${publisherId}`)
    .then((response) => response.list);
const SelectExamTypeAPI = (grade) =>
  axios.get(`master/selectExamType?grade=${grade}`);
// const GetExamTypeAPI = (params) => axios.get('master/newExamType', { params }).then(response => response)
const GetExamTypeAPI = (params) =>
  axios.get(`master/newLessons`, { params }).then((response) => response);
const GetStepGradeAPI = (level, publisherId) =>
  axios
    .get(`master/stepGrade?level=${level}&publisherId=${publisherId}`)
    .then((response) => response.list);

const GetLessonAPI = async (subjectId, grade, publisherId, level) =>
  axios
    .get(
      `master/newLessons?subjectId=${subjectId}&grade=${grade}&level=${level}&publisher=${publisherId}`
    )
    .then((response) => response.lessonData);

const GetSubjectByPathAPI = (grade, publisherId, level, showAll) =>
  axios
    .get(
      `master/subjects?grade=${grade}&showAll=${showAll}&level=${level}&publisherId=${publisherId}`
    )
    .then((response) => response.list);
const GetPublisherStepAPI = (level, subjectId, gradeId, lessonId) =>
  axios
    .get(
      `master/stepPublisher?level=${level}&subjectId=${subjectId}&gradeId=${gradeId}&lessonId=${lessonId}`
    )
    .then((response) => response.list);
const HandleTestHistoryService = (level, grade, subject) =>
  axios.get(
    `newStatistic/testingHistory/LIST?level=${level}&grade=${grade}&subject=${subject}`
  );
const ContactUsAPI = (params) => axios.post("master/sendContactUs", params);

export const getNewGradeService = () => handleRequest(GetNewGradeAPI());
export const getSubjectService = (params) =>
  handleRequest(GetSubjectAPI(params));
export const getPackagesService = () => handleRequest(getPackagesAPI());
export const getExpandSubjectService = (grade = "", level = "") =>
  handleRequest(getExpandSubjectAPI(grade, level));
export const getExpandCategoriesService = (params) =>
  handleRequest(GetExpandCategoriesAPI(params));
export const getGradeService = (params) => handleRequest(GetGradeAPI(params));
export const getTabGradeService = (
  publisherId = "",
  subjectId = "",
  level = ""
) => handleRequest(getTabGradeAPI(publisherId, subjectId, level));
export const getTermService = () => handleRequest(getTermAPI());
export const getPolicyService = () => handleRequest(getPolicyAPI());
export const getNews = () => handleRequest(GetNewsAPI());
export const GetEducationTypeService = (publisherId = "") =>
  handleRequest(GetEducationType(publisherId));
export const SelectExamTypeService = (grade = "") =>
  handleRequest(SelectExamTypeAPI(grade));
export const GetExamTypeService = (params) =>
  handleRequest(GetExamTypeAPI(params));
export const GetStepGradeService = (level = "", publisherId = "") =>
  handleRequest(GetStepGradeAPI(level, publisherId));
export const GetLessonService = ({
  subjectId = "",
  grade = "",
  level = "",
  publisherId = "",
}) => handleRequest(GetLessonAPI(subjectId, grade, publisherId, level));
export const GetSubjectByPathService = (
  grade = "",
  publisherId = "",
  level = "",
  showAll = ""
) => handleRequest(GetSubjectByPathAPI(grade, publisherId, level, showAll));
export const GetSubjectTabService = (
  grade = "",
  publisherId = "",
  level = "",
  showAll
) => handleRequest(GetSubjectTab(grade, publisherId, level, showAll));
export const GetPublisherStepService = (
  gradeId = "",
  lessonId = "",
  level = "",
  subjectId = ""
) => handleRequest(GetPublisherStepAPI(level, subjectId, gradeId, lessonId));
export const TestHistoryService = (level = "", grade = "", subject = "") =>
  handleRequest(HandleTestHistoryService(level, grade, subject));
export const ContactUsService = (params) => handleRequest(ContactUsAPI(params));
