import React, { useEffect } from 'react'
import IconFb from 'assets/icons/socialMedia/icon-facebook.svg'
import IconTw from 'assets/icons/socialMedia/icon-twiter.svg'
import IconIg from 'assets/icons/socialMedia/icon-instargam.svg'
import IconLi from 'assets/icons/socialMedia/icon-line.svg'
import ReactGA from 'react-ga'
import { Layout, BackTop, Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import styles from './LayoutExternal.module.scss'
import 'util/firebase-connector'

const LayoutExternal = (props) => {
  useEffect(() => {
    return () => props.children
  }, [])

  const sendGAEvent = (name) => {
    ReactGA.event({
      category: 'Footer',
      action: 'Click Menu Footer LayoutExternal',
      label: name
    })
  }
  return (
    <div className={styles.content}>
      <BackTop />
      <Layout className={styles.divLayout}>
        {props.children}
      </Layout>
      <div className={styles.divRoot} style={{ height: ((window.innerHeight >= 800 ) ? 300 : 150) }}>
        <div className={styles.divImg} />
        <div className={styles.divPadding}>
          <div className={styles.divSiteMap}>
            <Row>
              <Col span={6}>
                <span className={styles.title}>ข้อมูล</span>
                <Link to='/package'>
                  <span className={styles.list} onClick={() => {
                    sendGAEvent('package')
                  }}>แพ็กเกจ & ราคา</span>
                </Link>
              </Col>
              {/* /privacy-policy */}
              <Col span={6}>
                <span className={styles.title}>ข้อมูลทางกฎหมาย</span>
                <a href='/term-condition' target='_self'>
                  <span className={styles.list} onClick={() => {
                    sendGAEvent('term-condition')
                  }}>เงื่อนไขและข้อตกลงในการใช้งาน</span>
                </a>
                <a href='/privacy-policy' target='_self'>
                  <span className={styles.list} onClick={() => {
                    sendGAEvent('privacy-policy')
                  }}>นโยบายความเป็นส่วนตัว</span>
                </a>
              </Col>
              <Col span={6}>
                <span className={styles.title}>ช่วยเหลือ</span>
                <Link to='/contact'>
                  <span className={styles.list} onClick={() => {
                    sendGAEvent('contact')
                  }}>ติดต่อเรา</span>
                </Link>
              </Col>
              <Col span={6}>
                <span className={styles.title}>ช่องทางรับข่าวสาร</span>
                <span className={styles.list}>
                  <div className={styles.Oval} onClick={() => { sendGAEvent('facebook'); window.open('https://www.facebook.com/sobtidjunior', '_blank') }}>
                    <img alt='IconFb' src={IconFb} className={styles.imgIcon} />
                  </div>
                  <div className={styles.Oval} onClick={() => { sendGAEvent('twitter'); window.open('https://twitter.com/sobtidme?s=21', '_blank') }}>
                    <img alt='IconTw' src={IconTw} className={styles.imgIcon} />
                  </div>
                  <div className={styles.Oval} onClick={() => { sendGAEvent('instagram'); window.open('https://www.instagram.com/sobtidjunior/', '_blank') }}>
                    <img alt='IconIg' src={IconIg} className={styles.imgIcon} />
                  </div>
                  <div className={styles.Oval} onClick={() => { sendGAEvent('line'); window.open('https://lin.ee/HxYePHg', '_blank') }}>
                    <img alt='IconLi' src={IconLi} className={styles.imgIcon} />
                  </div>
                </span>
              </Col>
            </Row>
            <div style={{ marginTop: 24 }}>
              <span style={{ color: '#00913d', fontSize: 12 }}>Copyright © 2020 Sobtid Edutainment Co., Ltd. All rights reserved.</span>
            </div>

          </div>
          <div className={styles.divCopyRight}>
            <div>
              <span className={styles.copyRight}>Sobtid Edutainment Co., Ltd. products</span>
            </div>
            <div className={styles.sobtidLink}>
              <span onClick={() => { sendGAEvent('junior-landing'); window.open('https://sobtid.me/kid', '_blank') }}>สอบติด : จูเนียร์</span>
              <span onClick={() => { sendGAEvent('admission-landing'); window.open('https://sobtid.me/admission', '_blank') }}>สอบติด : Admission</span>
              <span onClick={() => { sendGAEvent('gov-landing'); window.open('https://sobtid.me/gov', '_blank') }}>สอบติด : ข้าราชการ</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
export default LayoutExternal