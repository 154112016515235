import React, { useState } from 'react'
import { Button, Input, Form, message } from 'antd'
// import IconClose from 'assets/icons/icon-close.svg'
import Title from 'components/Title/Title'
import styles from './ForgotPassword.module.scss'
import { submitForgotPassword, verifyOTPService, resetPassword } from 'api/auth'
import ConfirmStep from 'pages/Login/components/ConfirmStep'
// import ConfirmStep from './components/ConfirmStep'
import { TranslateLanguage } from 'util/Translate'
import { vocabForgotPassword } from 'util/Translate.variable'
import ModalSuccess from 'components/Modal/ModalSuccess'

const translate = new TranslateLanguage()
translate.addMaping(vocabForgotPassword)


function ForgotPassword(props) {
  const [step, setStep] = useState(1)
  const [valuesInput, setValuesInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessageWrongOTP, setErrorMessageWrongOTP] = useState('')
  const [verifyData, setVerifyData] = useState({
    memberId: '',
    otpCode: ''
  })
  const [dataResetPassword, setResetPassword] = useState({
    memberId: '',
    newPassword: ''
  })

  const [visibleSentVerifyModal, setVisibleSentVerifyModal] = useState(false)

  const submit = async values => {
    setIsLoading(true)
    setValuesInput(values)
    var emailRegex = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    var phoneRegex = /^(\+91-|\+91|0)?\d{10}$/
    // setStep(2)
    if (phoneRegex.test(values.email)) {
      const data = { phoneNumber: values.email }
      const res = await submitForgotPassword(data)
      if (res && !res.error) {
        verifyData['memberId'] = res.memberId
        setVerifyData(verifyData)
        setStep(2)
      }
      setIsLoading(false)
    } else if (emailRegex.test(String(values.email).toLowerCase())) {
      const data = { email: values.email }
      const res = await submitForgotPassword(data)
      if (res && !res.error) {
        // verifyData['memberId'] = res.memberId
        // setVerifyData(verifyData)
        setVisibleSentVerifyModal(true)
      }
      setIsLoading(false)
    }
  }
  const verifyOTP = async values => {
    setIsLoading(true)
    verifyData['otpCode'] = values.code
    await setVerifyData(verifyData)
    const res = await verifyOTPService(verifyData)
    setIsLoading(false)
    if (res && !res.error) {
      setErrorMessageWrongOTP('')
      if (res?.message) {
        message.success(res.message)
      }
      setStep(3)
    } else {
      setErrorMessageWrongOTP(res.error.message)
    }

  }
  const submitResetPassword = async values => {
    setIsLoading(true)
    dataResetPassword.memberId = verifyData.memberId
    dataResetPassword.newPassword = values.password
    setResetPassword(dataResetPassword)
    const res = await resetPassword(dataResetPassword)
    if (res && !res.error) {
      setIsLoading(false)
      message.success(res.message)
      setStep(1)
      // handleCancel()
    } else {
      setIsLoading(false)
      message.error(res.error.message)
      // setStep(1)
      // handleCancel()
    }

  }

  return (
    <div className={styles.rootForgotPassword}>
      {step === 1 && (<div>
        <div>
          <h2 className={styles.title}>{translate.convert('ลืมรหัสผ่าน')}</h2>
        </div>
        <div className={styles.divStep}>
          <Form
            name='form_forgot_password'
            initialValues={{
              remember: true,
            }}
            onFinish={submit}
          >
            <Form.Item
              className={styles.inputBox}
              name='email'
              rules={[
                {
                  required: true,
                  pattern: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))|(\+91-|\+91|0)?\d{10}$/,
                  message: translate.convert('กรุณากรอก อีเมล หรือ เบอร์โทรศัพท์'),
                },
              ]}
            >
              <Input className='inputForm' placeholder={translate.convert('อีเมล หรือ เบอร์โทรศัพท์ ที่ใช้สมัคร')} />
            </Form.Item>
            <Button type='primary' htmlType='submit' className={styles.btnLogin} loading={isLoading}>
              {translate.convert('ส่งคำร้อง')}
            </Button>
          </Form>
          <div className={styles.divBack}>
            <p
              className={styles.linkBack}
              onClick={() => {
                props.history.push('/login')
              }}
            >{translate.convert('ย้อนกลับ')}</p>
          </div>
        </div>
      </div>)}
      {step === 2 && (
        <ConfirmStep
          errorMessageWrongOTP={errorMessageWrongOTP}
          isLoading={isLoading}
          verifyOTP={verifyOTP}
          sendOTP={submit}
          verifyData={verifyData}
          setVerifyData={setVerifyData}
          valuesInput={valuesInput} />
      )}
      {step === 3 && (
        <div>
          <div>
            <Title>{translate.convert('รหัสผ่านใหม่')}</Title>
          </div>
          <div className={styles.divStep}>
            <Form
              name='form_login'
              initialValues={{
                remember: true,
              }}
              className={styles.formLogin}
              onFinish={submitResetPassword}
            >
              <Form.Item
                name='password'
                rules={[
                  {
                    required: true,
                    message: translate.convert('รหัสผ่านอย่างน้อย 8 ตัวอักษร'),
                    min: 8
                  },
                ]}
              >
                <Input.Password className='inputForm' placeholder={translate.convert('รหัสผ่านอย่างน้อย 8 ตัวอักษร')} />
              </Form.Item>
              <Form.Item
                name='confirm'
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: translate.convert('กรุณายืนยันรหัสผ่าน'),
                    min: 8
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject('รหัสผ่านไม่ตรงกัน')
                    },
                  }),
                ]}
              >
                <Input.Password className='inputForm' placeholder={translate.convert('ยืนยันรหัสผ่านใหม่')} />
              </Form.Item>
              <Button type='primary' htmlType='submit' className={styles.btnLogin} loading={isLoading}>
                {translate.convert('ส่งคำร้อง')}
              </Button>
            </Form>
          </div>
        </div>
      )}
      <ModalSuccess visible={visibleSentVerifyModal} onClose={() => setVisibleSentVerifyModal(false)} title={'ส่งคำร้องสำเร็จ'} content={'กรุณาตรวจสอบอีเมล หรือ หมายเลขโทรศัพท์ของท่าน และทำการเข้าสู่ระบบอีกครั้ง'} />
    </div>
  )
}

export default ForgotPassword