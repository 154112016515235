import React from 'react'
import styles from './Title.module.scss'

const Title = ({ children, size }) => {
  return (
    <div className={styles.title}>
      <div className={`${styles.regtangle} ${size ? styles[size] : ''}`} />
      <h2 className={`${styles.text} ${size ? styles[size] : '24px'}`}>{children}</h2>
    </div>
  )
}

export default Title