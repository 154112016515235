import React from 'react'
import { Menu } from 'antd'
// import styles from './MenuDropdown.module.scss'

const MenuDropdown = (options) => {
  return (
    <Menu
      placement='bottomRight'
      // arrow={true}
      items={options}
      onClick={({ key }) => {
        if (key && options[parseInt(key.substring(4))] && options[parseInt(key.substring(4))].action) {
          return options[parseInt(key.substring(4))].action(parseInt(key.substring(4)))
        }
      }}
    >
    </Menu>
  )
}

export default MenuDropdown