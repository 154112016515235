import React from 'react'
import styles from './ListDropdown.module.scss'

function ListDropdown(props) {
  
  return (
    <div className={styles.divMenu} onClick={props?.action}>
      <div className={styles.divItem}>
        <div className={styles.regTangle} />
        <div className={styles.divLabel}>
          {props?.icon && <img alt='icon' src={props?.icon} className={styles.icon} />}
          <span className={styles.txtMenu}> {props?.lable || props?.text}</span>
          {<div className={styles.divDivider} />}
        </div>
      </div>
    </div>
  )
}
// {d.text || d.name}
export default ListDropdown