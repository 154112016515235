import React, { useEffect, useState } from "react";
import { Button, Card, message } from "antd";
import moment from "moment";
import styles from "./CardPretest.module.scss";
import "util/firebase-connector";
import { getDatabase, ref, onValue } from "firebase/database";
import useAuth from "context/auth";
import useInterval from "util/useInterval";
import { dateAndTime, PretestStatus } from "util/helper";
import { textPretestButton } from "pages/Pretest/PretestDetail/PretestButton";
import { getFreePretestService } from "api/pretest";
import { getProfileService } from "api";
import { actionType } from "reducers/auth";
import { vocabCardPretest } from "util/Translate.variable";
import { TranslateLanguage } from "util/Translate";
import useComponentWidth from "hook/useComponentWidth";

const translate = new TranslateLanguage();
translate.addMaping(vocabCardPretest);
const { Meta } = Card;

// pretestFirebaseData.status
// undefined ยังไม่ขึ้น firebase
// 0: เปิดให้ทำหรือเริ่มต้นสร้าง จนถึง start
// 1: ตั้งแต่ start จนถึง end
// 2: หลัง end
// ฝึกซ้อม = เลยรอบจริงไปแล้ว status = 1

const CardPretest = React.memo(
  ({ history, pretest, onClickCard, fetchListPretests }) => {
    const {
      state: { user },
      dispatch,
    } = useAuth();
    const myComponentRef = React.useRef(null);
    const width = useComponentWidth(myComponentRef);

    const [pretestFirebaseData, setPretestFirebaseData] = useState({});
    const [saleTime, setSaleTime] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const { AllStatus } = PretestStatus(pretest, pretestFirebaseData, user);

    useEffect(() => {
      let isMounted = true;
      if (isMounted) {
        const subscribeFirebaseData = async () => {
          setIsLoading(true);
          const db = getDatabase();
          if (pretest?.firebaseUrl) {
            const response = await ref(db, pretest.firebaseUrl);
            onValue(response, (snap) => {
              setPretestFirebaseData(snap.val());
              setIsLoading(false);
            });
          } else {
            setIsLoading(false);
          }
          return null;
        };
        subscribeFirebaseData();
      }
      testFireBase();

      return () => {
        isMounted = false;
        localStorage.removeItem("now");
        localStorage.removeItem("isSale");
      };
    }, []);

    const testFireBase = async () => {
      const db = getDatabase();
      const dbRef = await ref(db, "pretests/test");

      onValue(dbRef, (snap) => {
        // console.log("snap", snap.val());
      });
    };

    const handleClickBuuton = async () => {
      if (isLoading) {
        return "";
      }
      if (pretestFirebaseData) {
        const doPretest = AllStatus.find(
          (status) =>
            status === "passTesting:2" ||
            status === "passTesting:false" ||
            status === "passTesting:true"
        );
        if (doPretest) {
          return history.push(`/pretest/${pretest.id}`);
        }
        const purchased = AllStatus.find(
          (stutus) =>
            stutus === "isPurchased:0" ||
            stutus === "isPurchased:1" ||
            stutus === "isPurchased:2"
        );
        if (purchased) {
          return history.push(`/pretest/${pretest.id}`);
        }
        if (pretest.price.cash && pretest.price.cash !== 0) {
          return history.push({
            pathname: "/payment",
            state: { type: "pretest", data: pretest },
            search: "typeExam=pretestExam",
          });
        }

        if (pretest?.price.cash === 0) {
          setIsLoading(true);
          const res = await getFreePretestService({
            pretestId: pretest.id,
            paymentType: "creditCard",
            currency: "cash",
          });
          if (res) {
            if (res?.message) {
              message.success(res.message);
            }
            const payload = await getProfileService();
            fetchListPretests();
            if (payload) {
              dispatch({ type: actionType.LOAD_PROFILE, user: payload });
            }
            setIsLoading(false);
          }
          if (res.error) {
            message.error(
              "ไม่สามารถทำข้อสอบชุดนี้ได้ กรุณาแจ้งเจ้าหน้าที่ดูแลระบบ"
            );
            setIsLoading(false);
            return;
          }
          return;
        }
      }
      return "";
    };

    const countUp = () => {
      const lowesSaleDate = [];
      if (!pretest.isSale) {
        localStorage.setItem("now", parseInt(moment().valueOf() / 1000));
        setSaleTime(parseInt(localStorage.getItem("now")) || null);
        lowesSaleDate.push(pretest?.saleDate);
        if (lowesSaleDate.length !== 0) {
          if (
            Math.min(...lowesSaleDate) < parseInt(moment().valueOf() / 1000)
          ) {
          }
        }
      }
    };
    useInterval(countUp, 1000);

    const isDisableActionBtn = () => {
      if (!pretest || !AllStatus) {
        return true;
      }
      const tested = JSON.parse(localStorage.getItem(pretest?.id));
      if (pretest.continuePretest === true || tested) {
        return false;
      }
      return (
        (AllStatus && AllStatus.includes("waitSale")) ||
        AllStatus.includes("isPurchased:0") ||
        AllStatus.includes("practiceTime:off")
      );
    };

    return (
      <Card
        hoverable
        card-itemid="card-pretest"
        cover={
          <img className={styles.banner} alt="banner" src={pretest.banner} />
        }
        className={styles.card}
        onClick={onClickCard}
        ref={myComponentRef}
      >
        <div className={styles.fix}>
          <div
            className={styles.hostLogo}
            style={{
              maxWidth: "3.5rem",
              maxHeight: "3.5rem",
              width: `${width / 5.5}px`,
              height: "auto",
            }}
          >
            <img alt="logo" src={pretest.host.logo} />
          </div>
          <div className={styles.info}>
            <div className={styles.meta}>
              <div className={styles.name}>{pretest.name}</div>
              <div className={styles.host}>{`${translate.convert("โดย")} ${
                pretest.host.name
              }`}</div>
            </div>
            {/*    Fix Date  */}
            {AllStatus.includes("waitSale") && (
              <p className={styles.startDate}>
                {translate.convert("เปิดขายวันที่")}{" "}
                {dateAndTime(pretest.saleDate * 1000)}{" "}
              </p>
            )}
            {AllStatus.includes("isSaleTime:0") && (
              <p className={styles.startDate}>
                {translate.convert("เริ่มสอบวันที่")}{" "}
                {dateAndTime(pretest.durationPretest.start * 1000)}{" "}
              </p>
            )}
            {AllStatus.includes("isSaleTime:1") && (
              <p className={styles.startDate}>
                {translate.convert("เข้าสอบได้ถึงวันที่")}{" "}
                {dateAndTime(pretest.durationPretest.end * 1000)}
              </p>
            )}
            {pretestFirebaseData && (
              <div
                className={`${
                  AllStatus.includes("waitSale") &&
                  AllStatus.includes("isSaleTime:0")
                    ? styles.wrapActionBtn
                    : styles.wrapActionBtnUp
                }`}
              >
                <Button
                  className={styles.btnCard}
                  loading={isLoading}
                  type="primary"
                  onClick={() => handleClickBuuton()}
                  disabled={isDisableActionBtn()}
                >
                  {AllStatus &&
                    textPretestButton(pretest, AllStatus, isLoading)}
                </Button>
              </div>
            )}
          </div>
        </div>
      </Card>
    );
  }
);
export default CardPretest;
