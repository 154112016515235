import axios, { handleRequest } from "./kiddy-connector";

const GetListPretestAPI = (params) =>
  axios.get("/pretest", { params }).then((response) => response.list);
const GetMyListPretestAPI = (params) =>
  axios.get("/pretest/myPretest", { params }).then((response) => response.list);
const GetListPretestDetailAPI = (id) => axios.get(`/pretest/${id}`);
const PurchasePretestAPI = (param) => axios.post("/pretest/purchase", param);
const ReportPretestAPI = (param) => axios.post("/pretest/report", param);
const PretestRankingAPI = (param) =>
  axios.get(`/pretestStatistic/pretestRanking?pretestId=${param}`);
const PretestAnalysisAPI = (param) =>
  axios.get(`/pretestStatistic/pretestAnalysis?pretestId=${param}`);
const PretestHistoryAPI = (param) =>
  axios.get(`/pretestStatistic/pretestHistory?pretestId=${param}`);
const PretestStaticResultAPI = (testingId) =>
  axios.get(`/pretestStatistic/pretestResult/${testingId}`);
const StartPretestAPI = (param) => axios.get(`/pretestTesting/start/${param}`);
const FinishedTestPretestAPI = (pretestId, param) =>
  axios.post(`/pretestTesting/finished/${pretestId}`, param);
const AnswerPretestAPI = (param) =>
  axios.get(`pretestStatistic/pretestAnswer?testingId=${param}`);
const ChangeDeviceStartPreTestAPI = (param) =>
  axios.get(`pretestTesting/changeDeviceStartTesting?${param}`);
const FavoritePretestAPI = (params) =>
  axios.patch(`/pretest/favorite/${params}`);
const UnfavoritePretest = (params) =>
  axios.patch(`/pretest/unfavorite/${params}`);
const RelatePretestAPI = (params) => axios.get(`/pretest/relate/${params}`);
const getFreePretestAPI = (params) => axios.get(`/pretest/getFree/${params}`);
const SearchPretestAPI = ({
  keyword,
  level = "",
  grade = "",
  subject = "",
  limit = "",
}) =>
  axios
    .get(
      `/pretest/search?keyword=${keyword}&level=${level}&grade=${grade}&subject=${subject}&limit=${limit}`
    )
    .then((response) => response.list);
const getMyPretestAPI = () =>
  axios.get(`/pretest/myPretest`).then((response) => response.list);
const getMyFavoritePretestAPI = () =>
  axios.get(`/pretest/myFavorite`).then((response) => response.list);
const postPurchasePretestAPI = ({
  pretestId,
  encryptCard,
  cardHolderName,
  cardHolderEmail,
  paymentType,
  amount,
}) =>
  axios
    .post("pretest/purchase", {
      pretestId,
      encryptCard,
      cardHolderName,
      cardHolderEmail,
      paymentType,
      amount,
    })
    .then((response) => response);
const favoritePretestAPI = ({ id }) => axios.patch(`/pretest/favorite/${id}`);
const unfavoritePretestAPI = ({ id }) =>
  axios.patch(`/pretest/unfavorite/${id}`);
const reviewPretest = ({ id, review }) =>
  axios.patch(`/pretest/review/${id}`, { reviewPoint: review });

export const getListPretestService = (params) => {
  return handleRequest(GetListPretestAPI(params));
};
export const getMyListPretestService = (params) => {
  return handleRequest(GetMyListPretestAPI(params));
};
export const getPretestDetailService = (id) => {
  return handleRequest(GetListPretestDetailAPI(id));
};
export const purchasePretestService = (param) => {
  return handleRequest(PurchasePretestAPI(param));
};
export const reportPretestService = (param) => {
  return handleRequest(ReportPretestAPI(param));
};
export const getPretestRankingService = (param) => {
  return handleRequest(PretestRankingAPI(param));
};
export const getPretestAnalysisService = (param) => {
  return handleRequest(PretestAnalysisAPI(param));
};
export const getPretestHistoryService = (param) => {
  return handleRequest(PretestHistoryAPI(param));
};
export const getPretestStaticResultService = (param) => {
  return handleRequest(PretestStaticResultAPI(param));
};
export const startPretestService = (param) => {
  return handleRequest(StartPretestAPI(param));
};
export const finishedPretestService = (pretestId, param) => {
  return handleRequest(FinishedTestPretestAPI(pretestId, param));
};
export const getAnswerPretestService = (param) => {
  return handleRequest(AnswerPretestAPI(param));
};
export const ChangeDeviceStartPreTestService = (param) => {
  return handleRequest(ChangeDeviceStartPreTestAPI(param));
};
export const FavoritePretestService = (params) => {
  return handleRequest(FavoritePretestAPI(params));
};
export const UnfavoritePretestService = (params) => {
  return handleRequest(UnfavoritePretest(params));
};
export const RelatePretestService = (params) => {
  return handleRequest(RelatePretestAPI(params));
};
export const getFreePretestService = (params) => {
  return handleRequest(getFreePretestAPI(params));
};
export const searchPretestSevice = ({
  keyword,
  level = "",
  grade = "",
  subject = "",
  limit = "50",
}) => {
  return handleRequest(
    SearchPretestAPI({ keyword, level, grade, subject, limit })
  );
};
export const getMyPretestService = () => {
  return handleRequest(getMyPretestAPI());
};
export const getMyFavoritePretestService = () => {
  return handleRequest(getMyFavoritePretestAPI());
};
export const postPurchasePretestService = ({
  pretestId,
  encryptCard,
  cardHolderName,
  cardHolderEmail,
  paymentType,
  amount,
}) => {
  return handleRequest(
    postPurchasePretestAPI({
      pretestId,
      encryptCard,
      cardHolderName,
      cardHolderEmail,
      paymentType,
      amount,
    })
  );
};
export const favoritePretestService = ({ id }) => {
  return handleRequest(favoritePretestAPI({ id }));
};
export const unfavoritePretestService = ({ id }) => {
  return handleRequest(unfavoritePretestAPI({ id }));
};
export const reviewPretestService = ({ id, review }) => {
  return handleRequest(reviewPretest({ id, review }));
};
