import React, { useEffect, useState } from "react";
import styles from "./PretestButton.module.scss";
import { Button } from "antd";
import { TranslateLanguage } from "util/Translate";
import { vocabPretestButton } from "util/Translate.variable";

const translate = new TranslateLanguage();
translate.addMaping(vocabPretestButton);

export const textPretestButton = (pretest, AllStatus, isLoading) => {
  if (isLoading) {
    return translate.convert("กำลังโหลด");
  }
  if (
    pretest.continuePretest === true ||
    JSON.parse(localStorage.getItem(pretest?.id))
  ) {
    return <span>{translate.convert("ทำต่อ")}</span>;
  }
  if (AllStatus) {
    const doPretest = AllStatus.find(
      (status) =>
        status === "passTesting:2" ||
        status === "passTesting:true" ||
        status === "isPurchased:2"
    );
    if (doPretest) {
      return translate.convert("ฝึกทำข้อสอบ");
    }
    const purchased = AllStatus.find(
      (stutus) => stutus === "isPurchased:0" || stutus === "isPurchased:1"
    );
    if (purchased) {
      return translate.convert("เริ่มทำข้อสอบ");
    }
    if (pretest.price.cash) {
      return `${pretest.price.cash} ${translate.convert("บาท")}`;
    }
    return translate.convert("ฟรี");
  }
  return "";
};
export const isPretestDisabled = () => {};

export const PretestTestingButton = (props) => {
  const { isDisableActionBtn, handleClick, AllStatus, pretest, isLoading } =
    props;
  const [text, setText] = useState("");

  useEffect(() => {
    if (pretest) {
      setText(textPretestButton(pretest, AllStatus, isLoading));
    }
  }, [isLoading, AllStatus, pretest]);

  return (
    <Button
      button-itemid="startTesting"
      type="primary"
      loading={isLoading}
      className={styles.btnPrimary}
      disabled={isDisableActionBtn()}
      onClick={handleClick}
    >
      {text}
    </Button>
  );
};
