import * as firebase from "firebase/app";
import { getDatabase } from "firebase/database";
import { getMessaging, getToken, isSupported } from "firebase/messaging";

let config = {};
if (process.env.REACT_APP_ENV === "production") {
  config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };
} else {
  config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  };
}

const firebaseApp = firebase.initializeApp(config)

export const getTokenFierbase = async () => {
  const suports = await isSupported();
  if (suports) {
    const messaging = getMessaging(firebaseApp);
    return getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_WEB_PUSH_CERT,
    })
      .then((res) => {
        return { result: true, response: res };
      })
      .catch((e) => {
        return e.code;
      });
  }
  throw "An error occurred while retrieving token.";
};

export const databaseFirebase = getDatabase(firebaseApp);
export default firebase;
