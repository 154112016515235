

export const propsButton = function (component, { props = '', value = '' } = {}) {
  this.component = component
  this.props = props
  this.value = value
}

propsButton.prototype = {
  checkDisable: function () {
    return checkButtonDisable({ component: this.component, props: this.props, value: this.value })
  },
  textContent : function (text) {
    return text
  }
}

const checkButtonDisable = ({ component, props, value }) => {
  if (component === 'ButtonDownload' && value === 'membersReport') {
    if (props.student) return !props.student.canReport
    return false
  }
  if (component === 'ButtonDownload' && value === 'historyReport') {
    if (props?.overAllExam) return !props?.overAllExam?.canReport
    return false
  }
  return false
}