import React, { useReducer, useEffect, useContext } from "react";
import { setAuthorizationToken } from "api/kiddy-connector";
import { identifierLocalStorage } from "util/localstorage";
import { isJwtTokenValid } from "util/helper";
import { authReducer, initialState, actionType } from "reducers/auth";
import { getProfileService } from "api";
import { message } from "antd";

export const AuthContext = React.createContext({
  state: initialState,
  dispatch: () => {},
});

export const AuthProvider = (props) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const _ident = identifierLocalStorage.get();

    if (!_ident || !_ident.jwtToken) {
      dispatch({ type: actionType.LOGOUT });
      return;
    }

    const { jwtToken, refreshToken } = _ident;

    if (isJwtTokenValid(jwtToken, refreshToken)) {
      setAuthorizationToken(jwtToken);
      dispatch({ type: actionType.LOGIN });
    } else {
      dispatch({ type: actionType.LOGOUT });
    }
  }, []);

  async function fetchUserProfile() {
    try {
      const payload = await getProfileService();
      dispatch({ type: actionType.LOAD_PROFILE, user: payload });
    } catch (error) {
      message.error("เกิดข้อผิดพลาดในการเรียกข้อมูล");
    }
  }

  return (
    <AuthContext.Provider
      value={{ state, dispatch, fetchUserProfile }}
      {...props}
    />
  );
};

export default function useAuth() {
  return useContext(AuthContext);
}
