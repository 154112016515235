import React from 'react'
import styles from './LayoutTesting.module.scss'

const LayoutTesting = (props) => {
  if (localStorage.getItem('isSale')) {
    localStorage.removeItem('isSale')
  }
  if (window.navigator.onLine === true) {
    localStorage.removeItem('internet')
    localStorage.setItem('internetStatus', 'online')
  } else if (window.navigator.onLine === false) {
    localStorage.setItem('internet', 'disconnected')
    localStorage.setItem('internetStatus', 'offLine')
  }

  return (
    <div className={styles.rootLayoutTesting}>
      {props.children}
    </div>
  )
}
export default LayoutTesting