import React from "react";
import { Modal, Button } from "antd";
import styles from "./Package.module.scss";
import IconSuccessBig from "assets/icons/icon_success_big.svg";
import { TranslateLanguage } from "util/Translate";
import { vacabModalPackage } from "util/Translate.variable";

const translate = new TranslateLanguage();
translate.addMaping(vacabModalPackage);

const ModalSuccess = (props) => {
  const {
    visible,
    setVisible,
    onOk = null,
    title = translate.convert("ยกเลิกแพ็กเกจสำเร็จ"),
    setBody = translate.convert(
      "ระบบได้ส่งอีเมลแจ้งเตือนไปยังอีเมลของคุณ \n กรุณาตรวจสอบอีเมล"
    ),
  } = props;

  const onClose = () => setVisible(false);
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      onOk={onOk}
      footer={false}
      bodyStyle={{ padding: "32px 64px", width: "500px", height: "431px" }}
      centered
    >
      <div className={styles.modal_success}>
        <img alt="IconSuccessBig" src={IconSuccessBig} />
        <h4 style={{ fontSize: "18px" }}>{title}</h4>
        <span>
          {setBody && setBody.includes("\n")
            ? setBody
                .split("\n")
                .map((value, i) => (
                  <p key={"p" + String(i)}> {translate.convert(value)} </p>
                ))
            : setBody}{" "}
        </span>
        <br />
        <Button onClick={onOk ? onOk : onClose} type="primary">
          {translate.convert("ตกลง")}{" "}
        </Button>
      </div>
    </Modal>
  );
};
export default ModalSuccess;
